import React, { useEffect, useState } from "react";
// import CpHeader from "../component/cp_header";

import CpLoading from "../component/cp_loading";
// import { loadContent } from "../lib/libFly";

function PBlog(props) {
  useEffect(() => {});

  return (
    <React.Fragment>
      <div class="mobile-menu" data-menu="dt-main-menu"></div>
      <div class="mobile-menu-overlay"></div>
      <div class="off-canvas--viewport" style={{ transform: "none" }}>
        <div id="CartDrawer" class="drawer drawer--right">
          <div class="drawer__header">
            <span class="drawer__close js-drawer-close dt-sc-btn close-icon"></span>
            <h4>My Cart</h4>
          </div>
          <div id="CartContainer"></div>
        </div>

        <div id="shopify-section-header" class="shopify-section"></div>
        <div
          data-section-id="header"
          data-section-type="header-section"
          data-header-section=""
        >
          <header id="header">
            <section class="dt-sc-header-top-bar">
              <div class="container-fluid spacing_enabled">
                <div class="row">
                  <div class="dt-sc-flex-space-between header-top">
                    <ul class="dt-sc-list-inline header-contact">
                      <li>
                        <a href="mailto:info@example.com">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                          info@example.com
                        </a>
                      </li>
                      <li>
                        <a href="tel:0000 - 123 - 456789">
                          <i class="fa fa-phone" aria-hidden="true"></i> 0000 -
                          123 - 456789
                        </a>
                      </li>
                    </ul>

                    <div class="dt-sc-list-inline"></div>

                    <div class="site-header__links dt-sc-flex">
                      <a
                        href=""
                        class="site-header__icon site-header__account"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path
                              d="M46.8,57c0.9,0,1.8,0,2.7,0c12,0,19.2-13.2,20.7-23.7c0.3-1.5,0.6-3.3,0.6-5.1C70.9,15.5,62.8,5,49.5,5
		S28.2,15.5,28.2,28.2C28.2,39.6,34.5,54.6,46.8,57z"
                            ></path>
                            <path
                              d="M64.3,60.6c-3.6-0.9-4.8-0.6-6.9,5.1l-7.2,20.7l-7.5-20.7c-2.1-5.7-3.3-6-6.9-5.1c-15.3,3.3-24,3.9-23.7,20.1
		v10.2c0,2.6,1.5,4,3,4c0,0,0,0,0,0h69.9v0c1.6,0,3.1-1.4,3.1-4.1V80.7C88.3,64.5,79.6,63.9,64.3,60.6z"
                            ></path>
                          </g>
                        </svg>

                        <span class="icon__fallback-text">My Account</span>
                      </a>

                      <form
                        method="post"
                        action=""
                        id="currency_form"
                        accept-charset="UTF-8"
                        class="shopify-currency-form"
                        enctype="multipart/form-data"
                      >
                        <input
                          type="hidden"
                          name="form_type"
                          value="currency"
                        />
                        <input type="hidden" name="utf8" value="✓" />
                        <input
                          type="hidden"
                          name="return_to"
                          value="/blogs/news"
                        />

                        <div class="custom-select">
                          <select
                            name="currency"
                            data-select2-id="select2-data-1-hdf7"
                            tabindex="-1"
                            class="select2-hidden-accessible"
                            aria-hidden="true"
                          >
                            <option
                              selected="true"
                              data-select2-id="select2-data-3-ik9l"
                            >
                              VNĐ
                            </option>
                          </select>
                          <span
                            class="select2 select2-container select2-container--classic"
                            dir="ltr"
                            data-select2-id="select2-data-2-5kdq"
                            style={{ width: "55px" }}
                          >
                            <span class="selection">
                              <span
                                class="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabindex="0"
                                aria-disabled="false"
                                aria-labelledby="select2-currency-jn-container"
                                aria-controls="select2-currency-jn-container"
                              >
                                <span
                                  class="select2-selection__rendered"
                                  id="select2-currency-jn-container"
                                  role="textbox"
                                  aria-readonly="true"
                                  title="USD"
                                >
                                  USD
                                </span>
                                <span
                                  class="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span
                              class="dropdown-wrapper"
                              aria-hidden="true"
                            ></span>
                          </span>
                        </div>
                      </form>

                      <a
                        href=""
                        class="site-header__icon site-header__wishlist"
                      >
                        <svg
                          class="heart-filled"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          xmlSpace="preserve"
                        >
                          <path
                            d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                          ></path>
                        </svg>

                        {/* <!--     <span class="icon__fallback-text">Wishlist</span> --> */}
                        <span
                          class="count dt-wishlist-cnt"
                          style={{ display: "none" }}
                        ></span>
                      </a>

                      <a
                        href=""
                        class="site-header__cart-toggle js-drawer-open-right dT_MiniCartModelBtn"
                        aria-controls="CartDrawer"
                        aria-expanded="false"
                        id="cart-number"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path
                              d="M94.4,22.7c-0.4-0.1-0.8-0.2-1.2-0.2H77.5h-7.8H46.1h-7.8h-9.8L25,12.8c-2.1-6-7.8-10-14.2-9.9H6.8c-2.2,0-3.9,1.8-3.9,3.9
		s1.8,3.9,3.9,3.9h3.9c3,0,5.7,1.9,6.7,4.7l15.2,42.4c1.7,4.7,6.1,7.8,11.1,7.8h32c5.1,0,9.6-3.2,11.2-8l10-30
		C97.6,25.6,96.5,23.4,94.4,22.7z"
                            ></path>
                            <circle cx="42.1" cy="85.3" r="11.8"></circle>
                            <circle cx="73.5" cy="85.3" r="11.8"></circle>
                          </g>
                        </svg>

                        <span class="icon__fallback-text">My Cart</span>
                        <div
                          id="CartCount"
                          data-cart_item_count="0"
                          data-show_cnt_zero="yes"
                          class="site-header__cart-count xhide"
                        >
                          <span class="count CartCount">0</span>
                          <span class="cart-words">items</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="dt-sc-header-logo-sec">
              <div class="container-fluid spacing_enabled">
                <div class="row">
                  <div class="logo-alignment dt-sc-menu_bottom">
                    <div id="sticky-position" class="scroll-nav">
                      <nav
                        id="AccessibleNav"
                        class="dt-sc-flex-space-between logo-search"
                        style={{ top: "50px" }}
                      >
                        <div class="header_search">
                          <div class="dt-sc-search-form">
                            <div
                              id="SearchDrawer"
                              class="dT_ProductProactiveSearch"
                            >
                              <form
                                action=""
                                method="get"
                              >
                                <input
                                  type="search"
                                  id="SearchInput"
                                  name="q"
                                  value=""
                                  placeholder="Search"
                                  aria-label="Search"
                                  autocomplete="off"
                                  class="search-bar__input"
                                />
                                <button type="submit" class="dt-sc-btn">
                                  <svg
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                    ></path>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  class="dt-sc-btn close dT_SearchClose"
                                  style={{ display: "none" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                                    ></path>
                                  </svg>
                                </button>
                              </form>
                              <div class="dT_PredictiveSearchResult_Section">
                                <ul class="dt-sc-ProductSugesstions"></ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="logo text-center">
                          <a href="">
                            <img
                              src="./"
                              alt=""
                              style={{ maxWidth: "250px" }}
                            />
                            {/* <!-- <img src="./News – Cooksy _ Kitchen Utensils_files/logo_1_300x300.png" alt="Cooksy | Kitchen Utensils" style="max-width: 250px"> --> */}
                          </a>
                        </div>

                        <div class="header-shipping-details">
                          <div class="header-shipping-icons"></div>
                          <div class="header-shipping-icons">
                            <div class="ship-icon">
                              <img
                                src="./News – Cooksy _ Kitchen Utensils_files/icons-6_40x40.png"
                                alt="BIG MONEY SAVING"
                                title="BIG MONEY SAVING"
                              />
                            </div>

                            <div class="ship-detail">
                              <h6>BIG MONEY SAVING</h6>
                              <p>Money Back Guarantee</p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="mobile-nav-container mobile-nav-offcanvas-right dt-header-menu"
                          data-menu="dt-main-menu"
                        >
                          <div class="menu-trigger" data-menu="dt-main-menu">
                            <i class="menu-trigger-icon"></i> <span>Menu</span>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <menu
              class="sticky-header sticky_remove_on_mobile"
              id="sticky-header"
            >
              <div class="container-fluid spacing_enabled">
                <nav
                  id="AccessibleNav"
                  role="navigation"
                  class="row dt-sc-flex-space-between"
                  data-menu="dt-main-menu"
                >
                  <div>
                    <ul
                      class="dt-sc-list-inline dt-desktop-menu dt-nav"
                      data-menu="dt-main-menu"
                    >
                      <li class="close-nav"></li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href=""
                          class="dt-sc-nav-link dropdown"
                        >
                          Home
                        </a>
                      </li>

                      {/* <!-- Product --><!-- Product End --> */}

                      <li class="2 promo_image top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                        <a
                          href=""
                          class="mega-menu dt-sc-nav-link dropdown"
                        >
                          Collection <span class="dt-sc-caret"></span>
                        </a>

                        <div
                          class="sub-menu-block is-hidden"
                          style={{ width: "1473px", left: "-455px" }}
                        >
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc--main-menu--mega dt-sc_main-menu--has-links"
                            id="collection-2-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists dt-sc-column four-column">
                              <li class="">
                                <h5>
                                  <a href="">
                                    REFRIGERATORS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="">
                                      Haier
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      LG
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Samsung
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Godrej M
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      itashi
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Bosch
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Kelvinator
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Kenstar
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li class="">
                                <h5>
                                  <a href="">
                                    DISHWASHERS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="">
                                      Siemens
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="">
                                      Glassiano
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/chimney-with-auto-clean">
                                      Kraft Italy
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/convection-microwave-oven">
                                      Whirlpool
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                                      Lithara
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/fry-pan-cooker">
                                      IFB
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/hand-blender-mixer">
                                      Bosch
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker">
                                      Frigidaire
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li class="">
                                <h5>
                                  <a href="https://dt-kitchen-utensils.myshopify.com/collections/special-product">
                                    FOOD PROCESSORS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/vegetable-slicer">
                                      Kenstar
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask">
                                      Usha
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/turbo-button-mixer">
                                      Prestige
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/tinny-coffee-maker">
                                      Singer
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/thermosteel-flip-lid-flask">
                                      Philips
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/style-vacuum-flask">
                                      Inalsa
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/copy-of-vegetable-slicer">
                                      Bajaj
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven">
                                      Maggi RIO
                                    </a>
                                  </li>
                                </ul>
                              </li>

                              {/* <!-- Image with text --><!-- Image with text  End--> */}

                              <li class="dt-sc-menu-image-with-text top-level-link">
                                <div class="dt-sc-mega_menu text-center">
                                  <img
                                    class="lazyload"
                                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1.jpg?v=1613763304"
                                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                    data-aspectratio="0.7477744807121661"
                                    data-sizes="auto"
                                    alt=""
                                  />
                                  <noscript>
                                    <img
                                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1_480x480@2x.jpg?v=1613763304"
                                      class="dt-sc-noscript-image"
                                    />
                                  </noscript>
                                  <div class="dt-sc-details"></div>
                                </div>
                              </li>
                              {/* <!-- Image with text  End--> */}
                            </ul>
                          </div>
                        </div>
                      </li>

                      {/* <!-- Product --><!-- Product End --> */}

                      <li class="0 megaProduct top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/all"
                          class="mega-menu dt-sc-nav-link dropdown"
                        >
                          Shop <span class="dt-sc-caret"></span>
                        </a>

                        <div
                          class="sub-menu-block is-hidden"
                          style={{ width: "1473px", left: "-567px" }}
                        >
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc--main-menu--mega"
                            id="shop-3-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists dt-sc-column four-column">
                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/400-watt-juicer-black">
                                      <img
                                        class="lazyautosizes lazyloaded"
                                        src="./News – Cooksy _ Kitchen Utensils_files/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="400-Watt Juicer (Black)"
                                        sizes="323px"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                          alt="400-Watt Juicer (Black)"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/400-watt-juicer-black"
                                    >
                                      400-Watt Juicer (Black)
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$245.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/blender-with-turbo-button">
                                      <img
                                        class="lazyautosizes lazyloaded"
                                        src="./News – Cooksy _ Kitchen Utensils_files/product-12.jpg"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12.jpg?v=1531907350"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Blender with Turbo Button"
                                        sizes="323px"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12_480x480@2x.jpg?v=1531907350"
                                          alt="Blender with Turbo Button"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/blender-with-turbo-button"
                                    >
                                      Blender with Turbo Button
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$329.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven">
                                      <img
                                        class="lazyautosizes lazyloaded"
                                        src="./News – Cooksy _ Kitchen Utensils_files/product-62.jpg"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62.jpg?v=1531907360"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Burner Stove with oven"
                                        sizes="323px"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_480x480@2x.jpg?v=1531907360"
                                          alt="Burner Stove with oven"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven"
                                    >
                                      Burner Stove with oven
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$530.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                                      <img
                                        class="lazyautosizes lazyloaded"
                                        src="./News – Cooksy _ Kitchen Utensils_files/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15.jpg"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15.jpg?v=1531907368"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Drip Coffee Maker"
                                        sizes="323px"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_480x480@2x.jpg?v=1531907368"
                                          alt="Drip Coffee Maker"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker"
                                    >
                                      Drip Coffee Maker
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$789.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/best-seller"
                          class="dt-sc-nav-link dropdown"
                        >
                          Major Kitchen
                        </a>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/coffee-maker"
                          class="dt-sc-nav-link dropdown"
                        >
                          Small Kitchen
                        </a>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/featured-product"
                          class="dt-sc-nav-link dropdown"
                        >
                          Appliance
                        </a>
                      </li>

                      <li class="1 text-menu top-level-link dt-sc-child menu-item-has-children">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/pages/about-us"
                          class="dt-sc-nav-link dropdown"
                        >
                          Pages <span class="dt-sc-caret"></span>
                        </a>

                        <div class="sub-menu-block is-hidden">
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc_main-menu--has-links"
                            id="pages-7-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists">
                              <li class=" ">
                                <a href="https://dt-kitchen-utensils.myshopify.com/pages/about-us">
                                  About us
                                </a>
                              </li>
                              <li class=" ">
                                <a href="https://dt-kitchen-utensils.myshopify.com/pages/contact-us">
                                  Contact us
                                </a>
                              </li>
                              <li class="active">
                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news"
                                  aria-current="page"
                                >
                                  Blog
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>{" "}
            </menu>
            <menu class="sticky-header-active" id="sticky-header-active">
              <div class="container-fluid spacing_enabled">
                <nav
                  id="AccessibleNav"
                  role="navigation"
                  class="row dt-sc-flex-space-between"
                  data-menu="dt-main-menu"
                >
                  <div>
                    <ul
                      class="dt-sc-list-inline dt-desktop-menu dt-nav"
                      data-menu="dt-main-menu"
                    >
                      <li class="close-nav"></li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/"
                          class="dt-sc-nav-link dropdown"
                        >
                          Home
                        </a>
                      </li>

                      {/* <!-- Product --><!-- Product End --> */}

                      <li class="2 promo_image top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections"
                          class="mega-menu dt-sc-nav-link dropdown"
                        >
                          Collection <span class="dt-sc-caret"></span>
                        </a>

                        <div
                          class="sub-menu-block is-hidden"
                          style={{ width: "1473px", left: "-455px" }}
                        >
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc--main-menu--mega dt-sc_main-menu--has-links"
                            id="collection-2-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists dt-sc-column four-column">
                              <li class="">
                                <h5>
                                  <a href="https://dt-kitchen-utensils.myshopify.com/collections/all">
                                    REFRIGERATORS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/1200-watt-induction-cooktop">
                                      Haier
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/400-watt-juicer-black">
                                      LG
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/660-watt-momo-cooker">
                                      Samsung
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/800-watt-juicer">
                                      Godrej M
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/blender-with-turbo-button">
                                      itashi
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven">
                                      Bosch
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/centrifugal-juicer">
                                      Kelvinator
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                                      Kenstar
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li class="">
                                <h5>
                                  <a href="https://dt-kitchen-utensils.myshopify.com/collections/all">
                                    DISHWASHERS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/copy-of-vegetable-slicer">
                                      Siemens
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/centrifugal-juicer">
                                      Glassiano
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/chimney-with-auto-clean">
                                      Kraft Italy
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/convection-microwave-oven">
                                      Whirlpool
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                                      Lithara
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/fry-pan-cooker">
                                      IFB
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/hand-blender-mixer">
                                      Bosch
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker">
                                      Frigidaire
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li class="">
                                <h5>
                                  <a href="https://dt-kitchen-utensils.myshopify.com/collections/special-product">
                                    FOOD PROCESSORS
                                  </a>
                                </h5>
                                <ul>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/vegetable-slicer">
                                      Kenstar
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask">
                                      Usha
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/turbo-button-mixer">
                                      Prestige
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/tinny-coffee-maker">
                                      Singer
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/thermosteel-flip-lid-flask">
                                      Philips
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/style-vacuum-flask">
                                      Inalsa
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/copy-of-vegetable-slicer">
                                      Bajaj
                                    </a>
                                  </li>
                                  <li class="">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven">
                                      Maggi RIO
                                    </a>
                                  </li>
                                </ul>
                              </li>

                              {/* <!-- Image with text --><!-- Image with text  End-->
                              <!-- Image with text --><!-- Image with text  End-->
                              <!-- Image with text --><!-- Image with text  End-->
                              <!-- Image with text --> */}
                              <li class="dt-sc-menu-image-with-text top-level-link">
                                <div class="dt-sc-mega_menu text-center">
                                  <img
                                    class="lazyload"
                                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1.jpg?v=1613763304"
                                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                    data-aspectratio="0.7477744807121661"
                                    data-sizes="auto"
                                    alt=""
                                  />
                                  <noscript>
                                    <img
                                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1_480x480@2x.jpg?v=1613763304"
                                      class="dt-sc-noscript-image"
                                    />
                                  </noscript>
                                  <div class="dt-sc-details"></div>
                                </div>
                              </li>
                              {/* <!-- Image with text  End--> */}
                            </ul>
                          </div>
                        </div>
                      </li>

                      {/* <!-- Product --><!-- Product End --> */}

                      <li class="0 megaProduct top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/all"
                          class="mega-menu dt-sc-nav-link dropdown"
                        >
                          Shop <span class="dt-sc-caret"></span>
                        </a>

                        <div
                          class="sub-menu-block is-hidden"
                          style={{ width: "1473px", left: "-567px" }}
                        >
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc--main-menu--mega"
                            id="shop-3-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists dt-sc-column four-column">
                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/400-watt-juicer-black">
                                      <img
                                        class="lazyload"
                                        src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="400-Watt Juicer (Black)"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                          alt="400-Watt Juicer (Black)"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/400-watt-juicer-black"
                                    >
                                      400-Watt Juicer (Black)
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$245.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/blender-with-turbo-button">
                                      <img
                                        class="lazyload"
                                        src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12.jpg?v=1531907350"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Blender with Turbo Button"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12_480x480@2x.jpg?v=1531907350"
                                          alt="Blender with Turbo Button"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/blender-with-turbo-button"
                                    >
                                      Blender with Turbo Button
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$329.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven">
                                      <img
                                        class="lazyload"
                                        src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62.jpg?v=1531907360"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Burner Stove with oven"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_480x480@2x.jpg?v=1531907360"
                                          alt="Burner Stove with oven"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven"
                                    >
                                      Burner Stove with oven
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$530.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>

                              <li class="dt-sc-menu-product">
                                <div class="dt-sc-menu-product__item">
                                  <div class="dt-sc-menu-product_item-image">
                                    <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                                      <img
                                        class="lazyload"
                                        src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15.jpg?v=1531907368"
                                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                        data-aspectratio="0.8514664143803217"
                                        data-sizes="auto"
                                        alt="Drip Coffee Maker"
                                      />
                                      <noscript>
                                        <img
                                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_480x480@2x.jpg?v=1531907368"
                                          alt="Drip Coffee Maker"
                                          class="dt-sc-noscript-image"
                                        />
                                      </noscript>
                                    </a>
                                  </div>
                                  <div class="dt-sc-menu-product_item-info">
                                    <a
                                      class="dt-sc-product__title"
                                      href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker"
                                    >
                                      Drip Coffee Maker
                                    </a>
                                    <span class="dt-sc-price">
                                      <span class="money">$789.00</span>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/best-seller"
                          class="dt-sc-nav-link dropdown"
                        >
                          Major Kitchen
                        </a>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/coffee-maker"
                          class="dt-sc-nav-link dropdown"
                        >
                          Small Kitchen
                        </a>
                      </li>

                      <li class="0 text-menu top-level-link dt-sc-child">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/collections/featured-product"
                          class="dt-sc-nav-link dropdown"
                        >
                          Appliance
                        </a>
                      </li>

                      <li class="1 text-menu top-level-link dt-sc-child menu-item-has-children">
                        <a
                          href="https://dt-kitchen-utensils.myshopify.com/pages/about-us"
                          class="dt-sc-nav-link dropdown"
                        >
                          Pages <span class="dt-sc-caret"></span>
                        </a>

                        <div class="sub-menu-block is-hidden">
                          <div class="go-back">
                            <a href="javascript:void(0);"></a>
                          </div>
                          <div class="see-all"></div>
                          <div
                            class="dt-sc-dropdown-menu dt-sc_main-menu--has-links"
                            id="pages-7-dt-sc-menu"
                          >
                            <ul class="sub-menu-lists">
                              <li class=" ">
                                <a href="https://dt-kitchen-utensils.myshopify.com/pages/about-us">
                                  About us
                                </a>
                              </li>
                              <li class=" ">
                                <a href="https://dt-kitchen-utensils.myshopify.com/pages/contact-us">
                                  Contact us
                                </a>
                              </li>
                              <li class="active">
                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news"
                                  aria-current="page"
                                >
                                  Blog
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </menu>
          </header>
        </div>
      </div>

      <nav class="breadcrumb text-center" aria-label="breadcrumbs">
        <div class="container breadcrumb-spacing">
          <h1>News</h1>
          <a
            href="https://dt-kitchen-utensils.myshopify.com/"
            title="Back to the frontpage"
          >
            Home
          </a>

          <span aria-hidden="true" class="breadcrumb__sep">
            /
          </span>

          <span>News</span>
        </div>
      </nav>

      <div class="clearfix"></div>

      <div
        class="shifter-page is-moved-by-drawer"
        id="container"
        style={{ transform: "none" }}
      >
        <div
          class="container-fluid spacing_enabled"
          style={{ transform: "none" }}
        >
          <div class="row" style={{ transform: "none" }}>
            <div class="container blog-container" style={{ transform: "none" }}>
              <div class="row" style={{ transform: "none" }}>
                <div
                  class="has-sidebar left-sidebar full-width"
                  style={{ transform: "none" }}
                >
                  <div class="blog-template-content">
                    <div
                      id="shopify-section-blog-template"
                      class="shopify-section index-section home-blog-section"
                    >
                      <div class="dt-sc-blog-section style-2 dt-sc-column two-column">
                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-glass-container">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Stunning glass container"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_180x.jpg?v=1535701309 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_360x.jpg?v=1535701309 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_470x.jpg?v=1535701309 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_600x.jpg?v=1535701309 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_770x.jpg?v=1535701309 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_970x.jpg?v=1535701309 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1060x.jpg?v=1535701309 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1280x.jpg?v=1535701309 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1512x.jpg?v=1535701309 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1728x.jpg?v=1535701309 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_2048x.jpg?v=1535701309 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_180x.jpg?v=1535701309   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_360x.jpg?v=1535701309   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_470x.jpg?v=1535701309   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_600x.jpg?v=1535701309   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_770x.jpg?v=1535701309   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_970x.jpg?v=1535701309   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1060x.jpg?v=1535701309 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1280x.jpg?v=1535701309 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1512x.jpg?v=1535701309 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1728x.jpg?v=1535701309 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_2048x.jpg?v=1535701309 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_480x480@2x.jpg?v=1535701309"
                                    alt="Stunning glass container"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:25:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                1 comment
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/container"
                                  class="article__grid-tag"
                                >
                                  Container
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-glass-container">
                                Stunning glass container
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Proin nibh augue, suscipit a,
                              scelerisque sed, lacinia in, mi. Cras vel lorem.
                              Etiam pellentesque aliquet tellus....
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-glass-container"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>

                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/branded-electric-rice-cooker">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Branded electric rice cooker"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_180x.jpg?v=1535700286 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_360x.jpg?v=1535700286 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_470x.jpg?v=1535700286 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_600x.jpg?v=1535700286 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_770x.jpg?v=1535700286 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_970x.jpg?v=1535700286 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1060x.jpg?v=1535700286 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1280x.jpg?v=1535700286 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1512x.jpg?v=1535700286 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1728x.jpg?v=1535700286 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_2048x.jpg?v=1535700286 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_180x.jpg?v=1535700286   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_360x.jpg?v=1535700286   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_470x.jpg?v=1535700286   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_600x.jpg?v=1535700286   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_770x.jpg?v=1535700286   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_970x.jpg?v=1535700286   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1060x.jpg?v=1535700286 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1280x.jpg?v=1535700286 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1512x.jpg?v=1535700286 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1728x.jpg?v=1535700286 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_2048x.jpg?v=1535700286 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_480x480@2x.jpg?v=1535700286"
                                    alt="Branded electric rice cooker"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:23:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                1 comment
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/rice-cooker"
                                  class="article__grid-tag"
                                >
                                  Rice Cooker
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/branded-electric-rice-cooker">
                                Branded electric rice cooker
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Eurs&nbsp;cum sociis natoque penatibus et magnis
                              dis parturient montes, nascetur ridiculus mus.
                              Donec quam felis, ultricies nec, pellentesque eu,
                              pretium quis, sem. Nulla consequat massa...
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/branded-electric-rice-cooker"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>

                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/wooden-bowl-and-plates">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Wooden bowl and plates"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_180x.jpg?v=1535700190 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_360x.jpg?v=1535700190 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_470x.jpg?v=1535700190 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_600x.jpg?v=1535700190 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_770x.jpg?v=1535700190 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_970x.jpg?v=1535700190 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1060x.jpg?v=1535700190 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1280x.jpg?v=1535700190 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1512x.jpg?v=1535700190 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1728x.jpg?v=1535700190 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_2048x.jpg?v=1535700190 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_180x.jpg?v=1535700190   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_360x.jpg?v=1535700190   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_470x.jpg?v=1535700190   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_600x.jpg?v=1535700190   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_770x.jpg?v=1535700190   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_970x.jpg?v=1535700190   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1060x.jpg?v=1535700190 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1280x.jpg?v=1535700190 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1512x.jpg?v=1535700190 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1728x.jpg?v=1535700190 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_2048x.jpg?v=1535700190 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_480x480@2x.jpg?v=1535700190"
                                    alt="Wooden bowl and plates"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:21:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                2 comments
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/bowl"
                                  class="article__grid-tag"
                                >
                                  Bowl
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/wooden-bowl-and-plates">
                                Wooden bowl and plates
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Oreum&nbsp;cum sociis natoque penatibus et magnis
                              dis parturient montes, nascetur ridiculus mus.
                              Donec quam felis, ultricies nec, pellentesque eu,
                              pretium quis, sem. Nulla consequat massa...
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/wooden-bowl-and-plates"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>

                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-look-wine-glasses">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Stunning look wine glasses"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_180x.jpg?v=1535700081 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_360x.jpg?v=1535700081 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_470x.jpg?v=1535700081 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_600x.jpg?v=1535700081 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_770x.jpg?v=1535700081 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_970x.jpg?v=1535700081 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1060x.jpg?v=1535700081 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1280x.jpg?v=1535700081 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1512x.jpg?v=1535700081 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1728x.jpg?v=1535700081 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_2048x.jpg?v=1535700081 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_180x.jpg?v=1535700081   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_360x.jpg?v=1535700081   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_470x.jpg?v=1535700081   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_600x.jpg?v=1535700081   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_770x.jpg?v=1535700081   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_970x.jpg?v=1535700081   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1060x.jpg?v=1535700081 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1280x.jpg?v=1535700081 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1512x.jpg?v=1535700081 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1728x.jpg?v=1535700081 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_2048x.jpg?v=1535700081 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_480x480@2x.jpg?v=1535700081"
                                    alt="Stunning look wine glasses"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:19:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                1 comment
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/glasses"
                                  class="article__grid-tag"
                                >
                                  Glasses
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-look-wine-glasses">
                                Stunning look wine glasses
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Marus&nbsp;cum sociis natoque penatibus et magnis
                              dis parturient montes, nascetur ridiculus mus.
                              Donec quam felis, ultricies nec, pellentesque eu,
                              pretium quis, sem. Nulla consequat massa...
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-look-wine-glasses"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>

                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/set-for-grocery-stand">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Set for grocery stand"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_180x.jpg?v=1535699989 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_360x.jpg?v=1535699989 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_470x.jpg?v=1535699989 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_600x.jpg?v=1535699989 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_770x.jpg?v=1535699989 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_970x.jpg?v=1535699989 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1060x.jpg?v=1535699989 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1280x.jpg?v=1535699989 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1512x.jpg?v=1535699989 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1728x.jpg?v=1535699989 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_2048x.jpg?v=1535699989 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_180x.jpg?v=1535699989   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_360x.jpg?v=1535699989   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_470x.jpg?v=1535699989   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_600x.jpg?v=1535699989   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_770x.jpg?v=1535699989   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_970x.jpg?v=1535699989   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1060x.jpg?v=1535699989 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1280x.jpg?v=1535699989 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1512x.jpg?v=1535699989 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1728x.jpg?v=1535699989 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_2048x.jpg?v=1535699989 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_480x480@2x.jpg?v=1535699989"
                                    alt="Set for grocery stand"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:19:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                1 comment
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/grocery-stand"
                                  class="article__grid-tag"
                                >
                                  Grocery Stand
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/set-for-grocery-stand">
                                Set for grocery stand
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Proin nibh augue, suscipit a,
                              scelerisque sed, lacinia in, mi. Cras vel lorem.
                              Etiam pellentesque aliquet tellus....
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/set-for-grocery-stand"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>

                        <div class="dt-sc-blog-item text-start grid-style vertical-top">
                          <div class="dt-sc-blog-image with-overlay-normal">
                            <div class="article__grid-image-wrapper">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/modern-and-classic-kitchen">
                                <img
                                  class="article__grid-image lazyautosizes lazyloaded"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.7761332099907492"
                                  data-sizes="auto"
                                  alt="Modern and classic kitchen"
                                  data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_180x.jpg?v=1535699564 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_360x.jpg?v=1535699564 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_470x.jpg?v=1535699564 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_600x.jpg?v=1535699564 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_770x.jpg?v=1535699564 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_970x.jpg?v=1535699564 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1060x.jpg?v=1535699564 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1280x.jpg?v=1535699564 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1512x.jpg?v=1535699564 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1728x.jpg?v=1535699564 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_2048x.jpg?v=1535699564 2048w"
                                  sizes="516px"
                                  srcset="
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_180x.jpg?v=1535699564   180w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_360x.jpg?v=1535699564   360w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_470x.jpg?v=1535699564   470w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_600x.jpg?v=1535699564   600w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_770x.jpg?v=1535699564   770w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_970x.jpg?v=1535699564   970w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1060x.jpg?v=1535699564 1060w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1280x.jpg?v=1535699564 1280w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1512x.jpg?v=1535699564 1512w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_1728x.jpg?v=1535699564 1728w,
                                    //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_2048x.jpg?v=1535699564 2048w
                                  "
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-7_480x480@2x.jpg?v=1535699564"
                                    alt="Modern and classic kitchen"
                                    class="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </a>
                            </div>
                          </div>
                          <div class="dt-sc-blog-content">
                            <div class="dt-sc-blog-meta">
                              <p class="dt-sc-blog-author">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 50 50"
                                >
                                  <path d="M25 1C11.8 1 1 11.8 1 25c0 7.1 3.1 13.5 8 17.9v0l0.3 0.3c0.1 0.1 0.1 0.1 0.2 0.1 0.4 0.4 0.9 0.7 1.3 1 0.1 0.1 0.2 0.2 0.4 0.3 0.5 0.3 1 0.7 1.5 1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.3 1.1 0.6 1.7 0.9 0 0 0 0 0 0 1.2 0.6 2.6 1.1 3.9 1.5 0 0 0.1 0 0.1 0 0.6 0.2 1.3 0.3 2 0.4 0.1 0 0.1 0 0.2 0 0.6 0.1 1.3 0.2 1.9 0.3 0.1 0 0.2 0 0.2 0C23.7 49 24.3 49 25 49s1.3 0 2-0.1c0.1 0 0.2 0 0.2 0 0.7-0.1 1.3-0.1 1.9-0.3 0.1 0 0.1 0 0.2 0 0.7-0.1 1.3-0.3 2-0.4 0 0 0.1 0 0.1 0 1.4-0.4 2.7-0.9 3.9-1.5 0 0 0 0 0 0 0.6-0.3 1.2-0.6 1.7-0.9 0.1 0 0.2-0.1 0.3-0.1 0.5-0.3 1-0.6 1.5-1 0.1-0.1 0.2-0.2 0.4-0.3 0.5-0.3 0.9-0.7 1.3-1 0.1-0.1 0.1-0.1 0.2-0.1l0.3-0.3v0c4.9-4.4 8-10.8 8-17.9C49 11.8 38.2 1 25 1zM25 25c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8S29.4 25 25 25zM28 27c6.1 0 11 4.9 11 11v4c0 0-0.1 0.1-0.1 0.1 -0.4 0.3-0.8 0.6-1.2 0.9 -0.1 0.1-0.2 0.1-0.3 0.2 -0.4 0.3-0.9 0.6-1.4 0.9 -0.1 0.1-0.2 0.1-0.3 0.1 -0.5 0.3-1 0.5-1.5 0.8 -0.1 0-0.1 0-0.2 0.1 -1.7 0.8-3.4 1.3-5.2 1.6 -0.1 0-0.1 0-0.2 0 -0.6 0.1-1.1 0.2-1.7 0.2 -0.1 0-0.2 0-0.2 0C26.2 47 25.6 47 25 47s-1.2 0-1.8-0.1c-0.1 0-0.2 0-0.2 0 -0.6-0.1-1.1-0.1-1.7-0.2 -0.1 0-0.1 0-0.2 0 -1.8-0.3-3.6-0.9-5.2-1.6 -0.1 0-0.1 0-0.2-0.1 -0.5-0.2-1-0.5-1.5-0.8 -0.1 0-0.2-0.1-0.3-0.1 -0.5-0.3-0.9-0.6-1.4-0.9 -0.1-0.1-0.2-0.1-0.3-0.2 -0.4-0.3-0.8-0.6-1.2-0.9 0 0-0.1-0.1-0.1-0.1V38c0-6.1 4.9-11 11-11H28zM41 40.1V38c0-6.3-4.5-11.5-10.4-12.7C33.3 23.5 35 20.4 35 17c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 3.4 1.7 6.5 4.4 8.3C13.5 26.5 9 31.7 9 38v2.1C5.3 36.1 3 30.8 3 25 3 12.9 12.9 3 25 3s22 9.9 22 22C47 30.8 44.7 36.1 41 40.1z"></path>
                                </svg>

                                <span>by Ram M</span>
                              </p>

                              <p class="dt-sc-blog-date">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M500.364,221.091c-6.982,0-11.636,4.655-11.636,11.636v46.545v69.818v11.636c0,12.8-10.473,23.273-23.273,23.273h-23.273
			c-19.782,0-34.909,15.127-34.909,34.909v23.273c0,12.8-10.473,23.273-23.273,23.273h-58.182H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V232.727c0-6.982-4.655-11.636-11.636-11.636S0,225.745,0,232.727v221.091
			c0,19.782,15.127,34.909,34.909,34.909h290.909H384h15.127c15.127,0,30.255-5.818,40.727-17.455l54.691-54.691
			c10.473-10.473,17.455-25.6,17.455-40.727v-15.127v-11.636v-69.818v-46.545C512,225.745,507.345,221.091,500.364,221.091z
			 M429.382,450.327c0-2.327,1.164-4.655,1.164-8.145v-23.273c0-6.982,4.655-11.636,11.636-11.636h23.273
			c2.327,0,4.655,0,8.145-1.164L429.382,450.327z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path
                                        d="M477.091,58.182h-81.455V34.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H139.636V34.909
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v23.273H34.909C15.127,58.182,0,73.309,0,93.091v93.091
			c0,6.982,4.655,11.636,11.636,11.636h488.727c6.982,0,11.636-4.655,11.636-11.636V93.091
			C512,73.309,496.873,58.182,477.091,58.182z M488.727,174.545H23.273V93.091c0-6.982,4.655-11.636,11.636-11.636h81.455v26.764
			c-6.982,3.491-11.636,11.636-11.636,19.782c0,12.8,10.473,23.273,23.273,23.273S151.273,140.8,151.273,128
			c0-8.145-4.655-16.291-11.636-19.782V81.455h232.727v26.764c-6.982,3.491-11.636,11.636-11.636,19.782
			c0,12.8,10.473,23.273,23.273,23.273S407.273,140.8,407.273,128c0-8.145-4.655-16.291-11.636-19.782V81.455h81.455
			c6.982,0,11.636,4.655,11.636,11.636V174.545z"
                                      ></path>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="395.636"
                                        cy="267.636"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="116.364"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="209.455"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <circle
                                        cx="302.545"
                                        cy="372.364"
                                        r="23.273"
                                      ></circle>
                                    </g>
                                  </g>
                                </svg>

                                <time datetime="2018-08-31T07:10:00Z">
                                  August 31, 2018
                                </time>
                              </p>

                              <p class="dt-sc-blog-comment">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 60 60"
                                >
                                  <path d="M54.1 1H15.9C12.7 1 10 3.7 10 6.9V15H5.9C2.7 15 0 17.7 0 20.9V42.1C0 45.3 2.7 48 5.9 48H12v10c0 0.4 0.3 0.8 0.6 0.9C12.8 59 12.9 59 13 59c0.3 0 0.5-0.1 0.7-0.3L23.4 48H44.1c3.3 0 5.9-2.7 5.9-5.9V34h4.1c3.3 0 5.9-2.7 5.9-5.9V6.9C60 3.7 57.3 1 54.1 1zM48 42.1c0 2.2-1.8 3.9-3.9 3.9H23c-0.3 0-0.6 0.1-0.7 0.3L14 55.4V47c0-0.6-0.4-1-1-1H5.9C3.8 46 2 44.2 2 42.1V20.9C2 18.8 3.8 17 5.9 17H11h33.1c0.8 0 1.6 0.2 2.2 0.7C47.3 18.4 48 19.6 48 20.9V33 42.1zM58 28.1c0 2.2-1.8 3.9-3.9 3.9H50V20.9v0c0-2.7-1.8-4.9-4.2-5.7 -0.2-0.1-0.4-0.1-0.6-0.1C44.9 15 44.5 15 44.1 15H12V6.9C12 4.8 13.8 3 15.9 3H54.1C56.2 3 58 4.8 58 6.9V28.1z"></path>
                                  <path d="M12 27h14c0.6 0 1-0.4 1-1s-0.4-1-1-1H12c-0.6 0-1 0.4-1 1S11.4 27 12 27z"></path>
                                  <path d="M39 31H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 31 39 31z"></path>
                                  <path d="M39 37H12c-0.6 0-1 0.4-1 1s0.4 1 1 1h27c0.6 0 1-0.4 1-1S39.6 37 39 37z"></path>
                                </svg>
                                1 comment
                              </p>
                              <p class="dt-sc-blog-tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="m121.5 64.2c-31.7 0-57.3 25.7-57.3 57.3 0 31.7 25.7 57.3 57.3 57.3 31.7 0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0 94.3c-20.4 0-37-16.6-37-37s16.6-37 37-37c20.4 0 37 16.6 37 37s-16.5 37-37 37z"></path>
                                  <path d="m244.5 29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9 0-44.8 8.3-60.5 25-16.7 15.7-25 37.6-25 60.5l1 107.4c1 14.6 6.3 29.2 17.7 40.7l256.5 256.4 214.8-214.8-256.5-256.4zm40.7 442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3 1c10.4 0 19.8 4.2 27.1 11.5l241.9 241.9-186.6 186.6z"></path>
                                </svg>

                                <a
                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/modern-kitchen"
                                  class="article__grid-tag"
                                >
                                  Modern Kitchen
                                </a>
                              </p>
                            </div>
                            <h4 class="dt-sc-blog-title">
                              <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/modern-and-classic-kitchen">
                                Modern and classic kitchen
                              </a>
                            </h4>

                            <div class="dt-sc-blog-description">
                              Vites&nbsp;cum sociis natoque penatibus et magnis
                              dis parturient montes, nascetur ridiculus mus.
                              Donec quam felis, ultricies nec, pellentesque eu,
                              pretium quis, sem. Nulla consequat massa...
                            </div>

                            <a
                              href="https://dt-kitchen-utensils.myshopify.com/blogs/news/modern-and-classic-kitchen"
                              class="dt-sc-btn"
                            >
                              Read more
                            </a>
                          </div>
                        </div>
                      </div>

                      <ul class="pagination-default blog-pagination text-center">
                        <li class="disabled">
                          <span class="fa fa-angle-left"></span>
                        </li>

                        <li class="active">
                          <span>1</span>
                        </li>

                        <li>
                          <a
                            href="https://dt-kitchen-utensils.myshopify.com/blogs/news?page=2"
                            title=""
                          >
                            2
                          </a>
                        </li>

                        <li>
                          <a
                            class="enable-arrow"
                            href="https://dt-kitchen-utensils.myshopify.com/blogs/news?page=2"
                            title="Next »"
                          >
                            <span class="fa fa-angle-right"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="text-center sidebar_btn">
                    <a class="dt-sc-btn toggleIcon">
                      <i class="fa fa-caret-right" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div
                    class="sidebar"
                    id="sidebar-sticky"
                    style={{
                      position: "relative",
                      overflow: "visible",
                      boxSizing: "border-box",
                      minHeight: "1px",
                    }}
                  >
                    <div
                      class="StickySidebar"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "1px",
                        position: "static",
                        top: "30px",
                        left: "51.4px",
                      }}
                    >
                      <div
                        id="shopify-section-blog-sidebar"
                        class="shopify-section"
                      >
                        <aside class="sidebar-height-blog-sidebar grid_style">
                          <a
                            href="https://dt-kitchen-utensils.myshopify.com/blogs/news#"
                            class="js-close-modal dt-sc-btn close-icon"
                          ></a>

                          <div class="widget product-sidebar-type-articles">
                            <h5 class="sidebar_title">Recent Articles</h5>

                            <div class="blog-sidebar-panel">
                              <ul class="recent_article">
                                <li class="article-item">
                                  <div class="article-image">
                                    <img
                                      class="article__grid-image lazyautosizes lazyloaded"
                                      src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="1.7761332099907492"
                                      data-sizes="auto"
                                      alt="Stunning glass container"
                                      data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_180x.jpg?v=1535701309 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_360x.jpg?v=1535701309 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_470x.jpg?v=1535701309 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_600x.jpg?v=1535701309 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_770x.jpg?v=1535701309 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_970x.jpg?v=1535701309 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1060x.jpg?v=1535701309 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1280x.jpg?v=1535701309 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1512x.jpg?v=1535701309 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1728x.jpg?v=1535701309 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_2048x.jpg?v=1535701309 2048w"
                                      sizes="111.8963922294172px"
                                      srcset="
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_180x.jpg?v=1535701309   180w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_360x.jpg?v=1535701309   360w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_470x.jpg?v=1535701309   470w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_600x.jpg?v=1535701309   600w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_770x.jpg?v=1535701309   770w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_970x.jpg?v=1535701309   970w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1060x.jpg?v=1535701309 1060w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1280x.jpg?v=1535701309 1280w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1512x.jpg?v=1535701309 1512w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_1728x.jpg?v=1535701309 1728w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_2048x.jpg?v=1535701309 2048w
                                      "
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-12_480x480@2x.jpg?v=1535701309"
                                        alt="Stunning glass container"
                                        class="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                  </div>

                                  <div class="article-description">
                                    <h6>
                                      <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-glass-container">
                                        Stunning glass container
                                      </a>
                                    </h6>
                                    <p>
                                      Tellus&nbsp;cum sociis natoque penatibus
                                      et...
                                    </p>
                                  </div>
                                </li>

                                <li class="article-item">
                                  <div class="article-image">
                                    <img
                                      class="article__grid-image lazyautosizes lazyloaded"
                                      src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="1.7761332099907492"
                                      data-sizes="auto"
                                      alt="Branded electric rice cooker"
                                      data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_180x.jpg?v=1535700286 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_360x.jpg?v=1535700286 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_470x.jpg?v=1535700286 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_600x.jpg?v=1535700286 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_770x.jpg?v=1535700286 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_970x.jpg?v=1535700286 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1060x.jpg?v=1535700286 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1280x.jpg?v=1535700286 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1512x.jpg?v=1535700286 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1728x.jpg?v=1535700286 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_2048x.jpg?v=1535700286 2048w"
                                      sizes="111.8963922294172px"
                                      srcset="
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_180x.jpg?v=1535700286   180w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_360x.jpg?v=1535700286   360w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_470x.jpg?v=1535700286   470w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_600x.jpg?v=1535700286   600w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_770x.jpg?v=1535700286   770w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_970x.jpg?v=1535700286   970w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1060x.jpg?v=1535700286 1060w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1280x.jpg?v=1535700286 1280w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1512x.jpg?v=1535700286 1512w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_1728x.jpg?v=1535700286 1728w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_2048x.jpg?v=1535700286 2048w
                                      "
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-11_480x480@2x.jpg?v=1535700286"
                                        alt="Branded electric rice cooker"
                                        class="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                  </div>

                                  <div class="article-description">
                                    <h6>
                                      <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/branded-electric-rice-cooker">
                                        Branded electric rice cooker
                                      </a>
                                    </h6>
                                    <p>
                                      Eurs&nbsp;cum sociis natoque penatibus
                                      et...
                                    </p>
                                  </div>
                                </li>

                                <li class="article-item">
                                  <div class="article-image">
                                    <img
                                      class="article__grid-image lazyautosizes lazyloaded"
                                      src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="1.7761332099907492"
                                      data-sizes="auto"
                                      alt="Wooden bowl and plates"
                                      data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_180x.jpg?v=1535700190 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_360x.jpg?v=1535700190 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_470x.jpg?v=1535700190 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_600x.jpg?v=1535700190 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_770x.jpg?v=1535700190 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_970x.jpg?v=1535700190 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1060x.jpg?v=1535700190 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1280x.jpg?v=1535700190 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1512x.jpg?v=1535700190 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1728x.jpg?v=1535700190 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_2048x.jpg?v=1535700190 2048w"
                                      sizes="111.8963922294172px"
                                      srcset="
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_180x.jpg?v=1535700190   180w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_360x.jpg?v=1535700190   360w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_470x.jpg?v=1535700190   470w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_600x.jpg?v=1535700190   600w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_770x.jpg?v=1535700190   770w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_970x.jpg?v=1535700190   970w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1060x.jpg?v=1535700190 1060w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1280x.jpg?v=1535700190 1280w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1512x.jpg?v=1535700190 1512w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_1728x.jpg?v=1535700190 1728w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_2048x.jpg?v=1535700190 2048w
                                      "
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-10_480x480@2x.jpg?v=1535700190"
                                        alt="Wooden bowl and plates"
                                        class="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                  </div>

                                  <div class="article-description">
                                    <h6>
                                      <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/wooden-bowl-and-plates">
                                        Wooden bowl and plates
                                      </a>
                                    </h6>
                                    <p>
                                      Oreum&nbsp;cum sociis natoque penatibus
                                      et...
                                    </p>
                                  </div>
                                </li>

                                <li class="article-item">
                                  <div class="article-image">
                                    <img
                                      class="article__grid-image lazyautosizes lazyloaded"
                                      src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="1.7761332099907492"
                                      data-sizes="auto"
                                      alt="Stunning look wine glasses"
                                      data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_180x.jpg?v=1535700081 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_360x.jpg?v=1535700081 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_470x.jpg?v=1535700081 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_600x.jpg?v=1535700081 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_770x.jpg?v=1535700081 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_970x.jpg?v=1535700081 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1060x.jpg?v=1535700081 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1280x.jpg?v=1535700081 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1512x.jpg?v=1535700081 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1728x.jpg?v=1535700081 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_2048x.jpg?v=1535700081 2048w"
                                      sizes="111.8963922294172px"
                                      srcset="
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_180x.jpg?v=1535700081   180w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_360x.jpg?v=1535700081   360w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_470x.jpg?v=1535700081   470w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_600x.jpg?v=1535700081   600w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_770x.jpg?v=1535700081   770w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_970x.jpg?v=1535700081   970w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1060x.jpg?v=1535700081 1060w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1280x.jpg?v=1535700081 1280w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1512x.jpg?v=1535700081 1512w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_1728x.jpg?v=1535700081 1728w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_2048x.jpg?v=1535700081 2048w
                                      "
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-9_480x480@2x.jpg?v=1535700081"
                                        alt="Stunning look wine glasses"
                                        class="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                  </div>

                                  <div class="article-description">
                                    <h6>
                                      <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/stunning-look-wine-glasses">
                                        Stunning look wine glasses
                                      </a>
                                    </h6>
                                    <p>
                                      Marus&nbsp;cum sociis natoque penatibus
                                      et...
                                    </p>
                                  </div>
                                </li>

                                <li class="article-item">
                                  <div class="article-image">
                                    <img
                                      class="article__grid-image lazyautosizes lazyloaded"
                                      src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="1.7761332099907492"
                                      data-sizes="auto"
                                      alt="Set for grocery stand"
                                      data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_180x.jpg?v=1535699989 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_360x.jpg?v=1535699989 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_470x.jpg?v=1535699989 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_600x.jpg?v=1535699989 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_770x.jpg?v=1535699989 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_970x.jpg?v=1535699989 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1060x.jpg?v=1535699989 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1280x.jpg?v=1535699989 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1512x.jpg?v=1535699989 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1728x.jpg?v=1535699989 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_2048x.jpg?v=1535699989 2048w"
                                      sizes="111.8963922294172px"
                                      srcset="
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_180x.jpg?v=1535699989   180w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_360x.jpg?v=1535699989   360w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_470x.jpg?v=1535699989   470w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_600x.jpg?v=1535699989   600w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_770x.jpg?v=1535699989   770w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_970x.jpg?v=1535699989   970w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1060x.jpg?v=1535699989 1060w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1280x.jpg?v=1535699989 1280w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1512x.jpg?v=1535699989 1512w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_1728x.jpg?v=1535699989 1728w,
                                        //dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_2048x.jpg?v=1535699989 2048w
                                      "
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/articles/blog-8_480x480@2x.jpg?v=1535699989"
                                        alt="Set for grocery stand"
                                        class="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                  </div>

                                  <div class="article-description">
                                    <h6>
                                      <a href="https://dt-kitchen-utensils.myshopify.com/blogs/news/set-for-grocery-stand">
                                        Set for grocery stand
                                      </a>
                                    </h6>
                                    <p>Cames cum sociis natoque penatibus...</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="widget product-sidebar-type-tags">
                            <h5 class="sidebar_title">Categories</h5>

                            <div class="blog-sidebar-panel">
                              <ul class="categories">
                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/bowl"
                                    title="Show articles tagged Bowl"
                                  >
                                    Bowl
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/container"
                                    title="Show articles tagged Container"
                                  >
                                    Container
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/glasses"
                                    title="Show articles tagged Glasses"
                                  >
                                    Glasses
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/grocery-stand"
                                    title="Show articles tagged Grocery Stand"
                                  >
                                    Grocery Stand
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/induction-stove"
                                    title="Show articles tagged Induction stove"
                                  >
                                    Induction stove
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/knifes"
                                    title="Show articles tagged Knifes"
                                  >
                                    Knifes
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/mixer"
                                    title="Show articles tagged Mixer"
                                  >
                                    Mixer
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/modern-kitchen"
                                    title="Show articles tagged Modern Kitchen"
                                  >
                                    Modern Kitchen
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/pans"
                                    title="Show articles tagged Pans"
                                  >
                                    Pans
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="https://dt-kitchen-utensils.myshopify.com/blogs/news/tagged/rice-cooker"
                                    title="Show articles tagged Rice Cooker"
                                  >
                                    Rice Cooker
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="dT_VProdWrapper widget product-sidebar-type-carousel">
                            <h5 class="sidebar_title">
                              Best Sellers
                              <div class="swiper-891a0969-9285-4427-87ea-c0e45c33bff2-arrows swiper-arrows">
                                <div
                                  id="swiper-891a0969-9285-4427-87ea-c0e45c33bff2-next"
                                  class="swiper-button-next dt-sc-btn blog-next"
                                  tabindex="0"
                                  role="button"
                                  aria-label="Next slide"
                                  aria-disabled="false"
                                ></div>
                                <div
                                  id="swiper-891a0969-9285-4427-87ea-c0e45c33bff2-prev"
                                  class="swiper-button-prev dt-sc-btn blog-prev swiper-button-disabled"
                                  tabindex="0"
                                  role="button"
                                  aria-label="Previous slide"
                                  aria-disabled="true"
                                ></div>
                              </div>
                            </h5>
                            <div
                              data-section-id="891a0969-9285-4427-87ea-c0e45c33bff2"
                              data-section-type="blog-sidebar-carousel-section"
                              data-item-per-view="1"
                              data-autoplay="true"
                              data-small-screen-items="1"
                              data-item-space="2"
                              data-auto-height="true"
                              class="product-sidebar-deals"
                            >
                              <div class="dt-sc-swiper-slider swiper-container navigation-bottom-center outside_element">
                                <div
                                  id="swiper-891a0969-9285-4427-87ea-c0e45c33bff2-slider"
                                  class="swiper-container-initialized swiper-container-horizontal"
                                >
                                  <ul
                                    class="swiper-wrapper"
                                    style={{
                                      transform: "translate3d(0px, 0px, 0px)",
                                    }}
                                  >
                                    <li
                                      id="product-1478742343745"
                                      class="swiper-slide grid-item product-grid-item on-sale swiper-slide-active"
                                      style={{
                                        width: "273px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <div class="products">
                                        <div class="product-container">
                                          <a
                                            href="https://dt-kitchen-utensils.myshopify.com/products/masala-mixer-300-watt"
                                            class="grid-link product-group"
                                          >
                                            <div class="featured-tag">
                                              <span class="badge badge--sale">
                                                <span class="gift-tag badge__text">
                                                  Sale
                                                </span>
                                              </span>
                                            </div>
                                            <div class="image_group">
                                              <div class="ImageOverlayCa"></div>
                                              <div class="reveal">
                                                <span class="product-additional">
                                                  <img
                                                    src="./News – Cooksy _ Kitchen Utensils_files/product-57_873b8f33-1595-4b60-881c-f3afe1f4f277.jpg"
                                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-57_873b8f33-1595-4b60-881c-f3afe1f4f277.jpg?v=1535717502"
                                                    alt="Masala Mixer (300 watt)"
                                                    class="featured-image teaser lazyloaded"
                                                  />
                                                </span>
                                                <img
                                                  src="./News – Cooksy _ Kitchen Utensils_files/pdt15_grande_ebbb3f25-a489-4584-8e58-f26d41e4e537.jpg"
                                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt15_grande_ebbb3f25-a489-4584-8e58-f26d41e4e537.jpg?v=1638616697"
                                                  alt="Masala Mixer (300 watt)"
                                                  class="hidden-feature_img teaser lazyloaded"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="product_right_tag offer_exist"></div>
                                          <div class="ImageWrapper">
                                            <div class="product-button dt-sc_icon icon-right">
                                              <div>
                                                <a
                                                  title="Select Options"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/masala-mixer-300-watt"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                      ></path>
                                                      <path
                                                        d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news#masala-mixer-300-watt"
                                                  aria-label="Add to wishlist"
                                                  data-product_handle="masala-mixer-300-watt"
                                                  data-product-handle="masala-mixer-300-watt"
                                                  class="dt-sc-btn dT_WhishListAddBtn"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-filled"
                                                  >
                                                    <path
                                                      d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                    ></path>
                                                  </svg>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-empty"
                                                  >
                                                    <path
                                                      d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                    ></path>
                                                  </svg>
                                                  <span>Add to wishlist</span>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  data-url="/products/masala-mixer-300-watt?view=quickview"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/masala-mixer-300-watt"
                                                  data-product-id="1478742343745"
                                                  data-slider-type="slider_gallery"
                                                  data-effect="mfp-move-from-top"
                                                  class="product-thumb-full-quick-view popup-product quick-view-btn"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="product-detail content-center">
                                          <h6 class="grid-link__title">
                                            <a href="https://dt-kitchen-utensils.myshopify.com/products/masala-mixer-300-watt">
                                              Masala Mixer (300 watt)
                                            </a>
                                          </h6>
                                          <span
                                            data-id="1478742343745"
                                            class="shopify-product-reviews-badge"
                                          ></span>
                                          <div class="grid-link__meta">
                                            <div class="product_price">
                                              <div
                                                id="ProductPrice"
                                                class="grid-link__org_price"
                                              >
                                                <span class="money">
                                                  $245.00
                                                </span>
                                              </div>
                                            </div>
                                            <del
                                              id="ComparePrice"
                                              class="grid-link__sale_price"
                                            >
                                              <span class="money">$400.00</span>
                                            </del>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      id="product-1384453439553"
                                      class="swiper-slide grid-item product-grid-item swiper-slide-next"
                                      style={{
                                        width: "273px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <div class="products">
                                        <div class="product-container">
                                          <a
                                            href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven"
                                            class="grid-link product-group"
                                          >
                                            <div class="image_group">
                                              <div class="ImageOverlayCa"></div>
                                              <div class="reveal">
                                                <span class="product-additional">
                                                  <img
                                                    src="./News – Cooksy _ Kitchen Utensils_files/product-16_708320d7-4051-4b59-90c6-738b053d3002.jpg"
                                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-16_708320d7-4051-4b59-90c6-738b053d3002.jpg?v=1531907382"
                                                    alt="Solo Microwave Oven"
                                                    class="featured-image teaser lazyloaded"
                                                  />
                                                </span>
                                                <img
                                                  src="./News – Cooksy _ Kitchen Utensils_files/product-10_f1df9faf-bc86-42fc-b425-2253dc3a0872.jpg"
                                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-10_f1df9faf-bc86-42fc-b425-2253dc3a0872.jpg?v=1531907382"
                                                  alt="Solo Microwave Oven"
                                                  class="hidden-feature_img teaser lazyloaded"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="product_right_tag"></div>
                                          <div class="ImageWrapper">
                                            <div class="product-button dt-sc_icon icon-right">
                                              <div>
                                                <a
                                                  title="Select Options"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                      ></path>
                                                      <path
                                                        d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news#solo-microwave-oven"
                                                  aria-label="Add to wishlist"
                                                  data-product_handle="solo-microwave-oven"
                                                  data-product-handle="solo-microwave-oven"
                                                  class="dt-sc-btn dT_WhishListAddBtn"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-filled"
                                                  >
                                                    <path
                                                      d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                    ></path>
                                                  </svg>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-empty"
                                                  >
                                                    <path
                                                      d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                    ></path>
                                                  </svg>
                                                  <span>Add to wishlist</span>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  data-url="/products/solo-microwave-oven?view=quickview"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven"
                                                  data-product-id="1384453439553"
                                                  data-slider-type="slider_gallery"
                                                  data-effect="mfp-move-from-top"
                                                  class="product-thumb-full-quick-view popup-product quick-view-btn"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="product-detail content-center">
                                          <h6 class="grid-link__title">
                                            <a href="https://dt-kitchen-utensils.myshopify.com/products/solo-microwave-oven">
                                              Solo Microwave Oven
                                            </a>
                                          </h6>
                                          <span
                                            data-id="1384453439553"
                                            class="shopify-product-reviews-badge"
                                          ></span>
                                          <div class="grid-link__meta">
                                            <div class="product_price">
                                              <div
                                                id="ProductPrice"
                                                class="grid-link__org_price"
                                              >
                                                <span class="money">
                                                  $349.00
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      id="product-1384453111873"
                                      class="swiper-slide grid-item product-grid-item"
                                      style={{
                                        width: "273px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <div class="products">
                                        <div class="product-container">
                                          <a
                                            href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker"
                                            class="grid-link product-group"
                                          >
                                            <div class="image_group">
                                              <div class="ImageOverlayCa"></div>
                                              <div class="reveal">
                                                <span class="product-additional">
                                                  <img
                                                    src="./News – Cooksy _ Kitchen Utensils_files/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9.jpg"
                                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9.jpg?v=1531907363"
                                                    alt="Multifunction Rice Cooker"
                                                    class="featured-image teaser lazyloaded"
                                                  />
                                                </span>
                                                <img
                                                  src="./News – Cooksy _ Kitchen Utensils_files/product-60.jpg"
                                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-60.jpg?v=1531907363"
                                                  alt="Multifunction Rice Cooker"
                                                  class="hidden-feature_img teaser lazyloaded"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="product_right_tag"></div>
                                          <div class="ImageWrapper">
                                            <div class="product-button dt-sc_icon icon-right">
                                              <div>
                                                <a
                                                  title="Select Options"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                      ></path>
                                                      <path
                                                        d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news#multifunction-rice-cooker"
                                                  aria-label="Add to wishlist"
                                                  data-product_handle="multifunction-rice-cooker"
                                                  data-product-handle="multifunction-rice-cooker"
                                                  class="dt-sc-btn dT_WhishListAddBtn"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-filled"
                                                  >
                                                    <path
                                                      d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                    ></path>
                                                  </svg>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-empty"
                                                  >
                                                    <path
                                                      d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                    ></path>
                                                  </svg>
                                                  <span>Add to wishlist</span>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  data-url="/products/multifunction-rice-cooker?view=quickview"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker"
                                                  data-product-id="1384453111873"
                                                  data-slider-type="slider_gallery"
                                                  data-effect="mfp-move-from-top"
                                                  class="product-thumb-full-quick-view popup-product quick-view-btn"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="product-detail content-center">
                                          <h6 class="grid-link__title">
                                            <a href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker">
                                              Multifunction Rice Cooker
                                            </a>
                                          </h6>
                                          <span
                                            data-id="1384453111873"
                                            class="shopify-product-reviews-badge"
                                          ></span>
                                          <div class="grid-link__meta">
                                            <div class="product_price">
                                              <div
                                                id="ProductPrice"
                                                class="grid-link__org_price"
                                              >
                                                <span class="money">
                                                  $320.00
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      id="product-1384453406785"
                                      class="swiper-slide grid-item product-grid-item"
                                      style={{
                                        width: "273px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <div class="products">
                                        <div class="product-container">
                                          <a
                                            href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask"
                                            class="grid-link product-group"
                                          >
                                            <div class="image_group">
                                              <div class="ImageOverlayCa"></div>
                                              <div class="reveal">
                                                <span class="product-additional">
                                                  <img
                                                    src="./News – Cooksy _ Kitchen Utensils_files/product-6.jpg"
                                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-6.jpg?v=1531907378"
                                                    alt="Unbrokeble Hot flask"
                                                    class="featured-image teaser lazyloaded"
                                                  />
                                                </span>
                                                <img
                                                  src="./News – Cooksy _ Kitchen Utensils_files/product-39.jpg"
                                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-39.jpg?v=1531907378"
                                                  alt="Unbrokeble Hot flask"
                                                  class="hidden-feature_img teaser lazyloaded"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="product_right_tag"></div>
                                          <div class="ImageWrapper">
                                            <div class="product-button dt-sc_icon icon-right">
                                              <div>
                                                <a
                                                  title="Select Options"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                      ></path>
                                                      <path
                                                        d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news#unbrokeble-hot-flask"
                                                  aria-label="Add to wishlist"
                                                  data-product_handle="unbrokeble-hot-flask"
                                                  data-product-handle="unbrokeble-hot-flask"
                                                  class="dt-sc-btn dT_WhishListAddBtn"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-filled"
                                                  >
                                                    <path
                                                      d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                    ></path>
                                                  </svg>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-empty"
                                                  >
                                                    <path
                                                      d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                    ></path>
                                                  </svg>
                                                  <span>Add to wishlist</span>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  data-url="/products/unbrokeble-hot-flask?view=quickview"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask"
                                                  data-product-id="1384453406785"
                                                  data-slider-type="slider_gallery"
                                                  data-effect="mfp-move-from-top"
                                                  class="product-thumb-full-quick-view popup-product quick-view-btn"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="product-detail content-center">
                                          <h6 class="grid-link__title">
                                            <a href="https://dt-kitchen-utensils.myshopify.com/products/unbrokeble-hot-flask">
                                              Unbrokeble Hot flask
                                            </a>
                                          </h6>
                                          <span
                                            data-id="1384453406785"
                                            class="shopify-product-reviews-badge"
                                          ></span>
                                          <div class="grid-link__meta">
                                            <div class="product_price">
                                              <div
                                                id="ProductPrice"
                                                class="grid-link__org_price"
                                              >
                                                <span class="money">
                                                  $329.00
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      id="product-1478683689025"
                                      class="swiper-slide grid-item product-grid-item"
                                      style={{
                                        width: "273px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <div class="products">
                                        <div class="product-container">
                                          <a
                                            href="https://dt-kitchen-utensils.myshopify.com/products/hand-mixer-tool"
                                            class="grid-link product-group"
                                          >
                                            <div class="image_group">
                                              <div class="ImageOverlayCa"></div>
                                              <div class="reveal">
                                                <span class="product-additional">
                                                  <img
                                                    src="./News – Cooksy _ Kitchen Utensils_files/pdt10.jpg"
                                                    data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt10.jpg?v=1535712554"
                                                    alt="Hand Mixer Tool"
                                                    class="featured-image teaser lazyloaded"
                                                  />
                                                </span>
                                                <img
                                                  src="./News – Cooksy _ Kitchen Utensils_files/product-50_1b527067-92c2-4823-b96f-69acd20aa494.jpg"
                                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-50_1b527067-92c2-4823-b96f-69acd20aa494.jpg?v=1535712554"
                                                  alt="Hand Mixer Tool"
                                                  class="hidden-feature_img teaser lazyloaded"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="product_right_tag"></div>
                                          <div class="ImageWrapper">
                                            <div class="product-button dt-sc_icon icon-right">
                                              <div>
                                                <a
                                                  title="Select Options"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/hand-mixer-tool"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                      ></path>
                                                      <path
                                                        d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  href="https://dt-kitchen-utensils.myshopify.com/blogs/news#hand-mixer-tool"
                                                  aria-label="Add to wishlist"
                                                  data-product_handle="hand-mixer-tool"
                                                  data-product-handle="hand-mixer-tool"
                                                  class="dt-sc-btn dT_WhishListAddBtn"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-filled"
                                                  >
                                                    <path
                                                      d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                    ></path>
                                                  </svg>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                    class="heart-empty"
                                                  >
                                                    <path
                                                      d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                    ></path>
                                                  </svg>
                                                  <span>Add to wishlist</span>
                                                </a>
                                              </div>
                                              <div>
                                                <a
                                                  data-url="/products/hand-mixer-tool?view=quickview"
                                                  href="https://dt-kitchen-utensils.myshopify.com/products/hand-mixer-tool"
                                                  data-product-id="1478683689025"
                                                  data-slider-type="slider_gallery"
                                                  data-effect="mfp-move-from-top"
                                                  class="product-thumb-full-quick-view popup-product quick-view-btn"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="product-detail content-center">
                                          <h6 class="grid-link__title">
                                            <a href="https://dt-kitchen-utensils.myshopify.com/products/hand-mixer-tool">
                                              Hand Mixer Tool
                                            </a>
                                          </h6>
                                          <span
                                            data-id="1478683689025"
                                            class="shopify-product-reviews-badge"
                                          ></span>
                                          <div class="grid-link__meta">
                                            <div class="product_price">
                                              <div
                                                id="ProductPrice"
                                                class="grid-link__org_price"
                                              >
                                                <span class="money">
                                                  $329.00
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <span
                                    class="swiper-notification"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="widget product-sidebar-type-image"></div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
          </div>

          <div id="shopify-section-footer" class="shopify-section">
            <footer
              class="site-footer"
              data-section-id="footer"
              data-section-type="footer-section"
              style={{
                marginTop: "40px",
                backgroundImage:
                  "url(&#39;//dt-kitchen-utensils.myshopify.com/cdn/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif&#39;)",
                backgrounSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                class="site-footer__top"
                style={{ paddingTop: "40px", paddingBottom: "40px" }}
              >
                <div class="container">
                  <div class="row">
                    <div class="dt-sc-flex-space-between footer-align">
                      <div class="footer__item--3ae2a44c-6425-4746-b0ab-44454f47cb25 footer-contact-info">
                        <div class="text-start">
                          <h4 class="footer__title">Contact us</h4>

                          <ul class="contact-info">
                            <li class="address">
                              <address>
                                <i class="fa fa-home"></i>No: 58 A, East Madison
                                Street, Baltimore, MD, USA 4508
                              </address>
                            </li>

                            <li class="contact-phone">
                              <i class="fa fa-phone"></i>0000 - 123 - 456789
                            </li>

                            <li class="office-hours">
                              <i class="fa fa-clock-o"></i>9.30AM - 7.30PM
                            </li>

                            <li class="office-mail">
                              <a href="mailto:info@example.com">
                                <i class="fa fa-envelope"></i>info@example.com
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="footer__item--55c7a363-48ef-4d8e-b9bf-c930008bde8a footer-links">
                        <div class="text-start">
                          <h4 class="footer__title">Help</h4>

                          <ul class="footer_menu dt-sc-list">
                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/search">
                                Search
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/faqs">
                                Help
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/shipping-details">
                                Information
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/contact-us">
                                Privacy Policy
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/shipping-details">
                                Shipping Delails
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="footer__item--f01d3e76-7d47-4753-9d4a-d9f970895cd2 footer-links">
                        <div class="text-start">
                          <h4 class="footer__title">Support</h4>

                          <ul class="footer_menu dt-sc-list">
                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/contact-us">
                                Contact us
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/about-us">
                                About us
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/faqs">
                                Careers
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/wishlist">
                                Refunds &amp; Returns
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/shipping-details">
                                Deliveries
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="footer__item--a7eb42ee-501b-4d5e-affd-1f06bc09f583 footer-links">
                        <div class="text-start">
                          <h4 class="footer__title">Information</h4>

                          <ul class="footer_menu dt-sc-list">
                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/search">
                                Search Terms
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/faqs">
                                Advanced Search
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/faqs">
                                Help &amp; Faq's
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/contact-us">
                                Store Location
                              </a>
                            </li>

                            <li>
                              <a href="https://dt-kitchen-utensils.myshopify.com/pages/wishlist">
                                Orders &amp; Returns
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="site-footer__bottom">
                <div class="container">
                  <div class="row">
                    <div class="dt-sc-flex-space-between footer-bottom">
                      <div class="footer__copyright grid__item wide--one-third post-large--one-third large--one-third medium--one-whole">
                        <p>
                          © 2024 Cooksy | Kitchen Utensils
                          <a
                            href="https://themeforest.net/user/designthemes/portfolio"
                            target="_blank"
                          >
                            Design Themes
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>

          <div
            id="shopify-section-modal-newsletter"
            class="shopify-section dt-sc-newsletter-modal-overlay"
          >
            <div
              class="dt-sc-newsletter-container"
              id="dt-sc-newsletter-container"
              style={{ display: "none" }}
            >
              <span class="dt-sc-newsletter-container-overlay"></span>
              <div
                class="dt-sc-newsletter-modal lazyload dt-sc-overlay text-center"
                data-bgset=""
                data-sizes="auto"
                data-parent-fit="cover"
                data-image-loading-animation=""
              >
                {/* <!--     <div>
    	<img src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/popup_newsletter_img_480x480@2x.jpg?v=1633497744" class="dt-sc-noscript-image" />
    </div> --> */}
                <div class="">
                  <div class="dt-sc-newsletter-content style-3">
                    <h3 style={{ color: "rgba(0, 0, 0, 0)", color: "#000000" }}>
                      Get to Know the latest offers
                    </h3>
                    <p style={{ color: "rgba(0, 0, 0, 0)", color: "#000000" }}>
                      Subscribe and get notified at first on the latest update
                      and offers!
                    </p>
                    <form
                      method="post"
                      action="https://dt-kitchen-utensils.myshopify.com/contact#Contact_modal-newsletter"
                      id="Contact_modal-newsletter"
                      accept-charset="UTF-8"
                      class="contact-form"
                      novalidate="novalidate"
                    >
                      <input type="hidden" name="form_type" value="customer" />
                      <input type="hidden" name="utf8" value="✓" />

                      <input
                        type="email"
                        placeholder="Enter your email id"
                        value=""
                        name="contact[email]"
                        class="mail"
                        aria-label="Your email address"
                        required="required"
                      />
                      <input
                        type="hidden"
                        name="contact[tags]"
                        value="newsletter"
                      />
                      <button type="submit" class="dt-sc-btn">
                        <span>Submit</span>
                        <i class="fa fa-envelope"></i>
                      </button>
                    </form>
                  </div>
                  <p
                    style={{ color: "rgba(0, 0, 0, 0)", color: "#000000" }}
                    class="dt-sc-newsletter-additional-content"
                  >
                    We do not span
                  </p>
                  <a class="dt-sc-close-window dt-sc-btn close-icon"></a>

                  <div class="">
                    <ul class="dt-sc-social-icons">
                      <li>
                        <a
                          class="icon-fallback-text fb hexagon"
                          target="blank"
                          href="https://dt-kitchen-utensils.myshopify.com/blogs/news#"
                          title="Facebook"
                        >
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                          <span class="icon__fallback-text">Facebook</span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="icon-fallback-text pin hexagon"
                          target="blank"
                          href="https://dt-kitchen-utensils.myshopify.com/blogs/news#"
                          title="Pinterest"
                        >
                          <i class="fa fa-pinterest" aria-hidden="true"></i>
                          <span class="icon__fallback-text">Pinterest</span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="icon-fallback-text ins"
                          target="blank"
                          href="https://dt-kitchen-utensils.myshopify.com/blogs/news#"
                          title="Instagram"
                        >
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                          <span class="icon__fallback-text">Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <noscript>
                    <img
                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/popup_newsletter_img_480x480@2x.jpg?v=1633497744"
                      class="dt-sc-noscript-image"
                    />
                  </noscript>
                </div>
              </div>
            </div>
          </div>
          <div
            id="shopify-section-gdpr-cookies"
            class="shopify-section gdpr-section"
          >
            <div class="cookie-disclaimer bottom">
              <div class="cookie-content">
                <p>Please click Accept Cookies to continue to use the site.</p>
                <button type="button" class="accept-cookie">
                  Accept
                </button>
                <button type="button" class="decline-cookie">
                  Decline
                </button>
              </div>
            </div>
          </div>
          <div
            id="shopify-section-customer-purchased"
            class="shopify-section customer-purchased"
          >
            <ul class="customer-who-purchased text-right">
              <li class="product-data">
                <a href="https://dt-kitchen-utensils.myshopify.com/products/660-watt-momo-cooker">
                  <img
                    class="lazyautosizes ls-is-cached lazyloaded"
                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                    data-aspectratio="0.8514664143803217"
                    data-sizes="auto"
                    alt="660-Watt Momo Cooker"
                    data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_180x.jpg?v=1531907354 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_360x.jpg?v=1531907354 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_470x.jpg?v=1531907354 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_600x.jpg?v=1531907354 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_770x.jpg?v=1531907354 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_970x.jpg?v=1531907354 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1060x.jpg?v=1531907354 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1280x.jpg?v=1531907354 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1512x.jpg?v=1531907354 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1728x.jpg?v=1531907354 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_2048x.jpg?v=1531907354 2048w"
                    sizes="60px"
                    srcset="
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_180x.jpg?v=1531907354   180w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_360x.jpg?v=1531907354   360w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_470x.jpg?v=1531907354   470w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_600x.jpg?v=1531907354   600w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_770x.jpg?v=1531907354   770w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_970x.jpg?v=1531907354   970w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1060x.jpg?v=1531907354 1060w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1280x.jpg?v=1531907354 1280w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1512x.jpg?v=1531907354 1512w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_1728x.jpg?v=1531907354 1728w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_2048x.jpg?v=1531907354 2048w
                    "
                  />
                  <noscript>
                    <img
                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-61_480x480@2x.jpg?v=1531907354"
                      alt="660-Watt Momo Cooker"
                      class="dt-sc-noscript-image"
                    />
                  </noscript>
                  <span class="title">660-Watt Momo Cooker</span>
                </a>
                <p>
                  <span class="purchased">Someone recently bought</span>from
                  <span class="location">Texas, US</span>
                  <span class="timing">10 minutes ago</span>
                </p>
                <a href="javascript:void(0)" title="Close" class="dT_close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <path
                      d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="product-data">
                <a href="https://dt-kitchen-utensils.myshopify.com/products/burner-stove-with-oven">
                  <img
                    class="lazyautosizes ls-is-cached lazyloaded"
                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                    data-aspectratio="0.8514664143803217"
                    data-sizes="auto"
                    alt="Burner Stove with oven"
                    data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_180x.jpg?v=1531907360 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_360x.jpg?v=1531907360 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_470x.jpg?v=1531907360 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_600x.jpg?v=1531907360 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_770x.jpg?v=1531907360 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_970x.jpg?v=1531907360 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1060x.jpg?v=1531907360 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1280x.jpg?v=1531907360 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1512x.jpg?v=1531907360 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1728x.jpg?v=1531907360 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_2048x.jpg?v=1531907360 2048w"
                    sizes="60px"
                    srcset="
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_180x.jpg?v=1531907360   180w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_360x.jpg?v=1531907360   360w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_470x.jpg?v=1531907360   470w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_600x.jpg?v=1531907360   600w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_770x.jpg?v=1531907360   770w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_970x.jpg?v=1531907360   970w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1060x.jpg?v=1531907360 1060w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1280x.jpg?v=1531907360 1280w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1512x.jpg?v=1531907360 1512w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_1728x.jpg?v=1531907360 1728w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_2048x.jpg?v=1531907360 2048w
                    "
                  />
                  <noscript>
                    <img
                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_480x480@2x.jpg?v=1531907360"
                      alt="Burner Stove with oven"
                      class="dt-sc-noscript-image"
                    />
                  </noscript>
                  <span class="title">Burner Stove with oven</span>
                </a>
                <p>
                  <span class="purchased">Someone recently bought</span>from
                  <span class="location">Newyork, US</span>
                  <span class="timing">45 minutes ago</span>
                </p>
                <a href="javascript:void(0)" title="Close" class="dT_close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <path
                      d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="product-data">
                <a href="https://dt-kitchen-utensils.myshopify.com/products/drip-coffee-maker">
                  <img
                    class="lazyautosizes ls-is-cached lazyloaded"
                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                    data-aspectratio="0.8514664143803217"
                    data-sizes="auto"
                    alt="Drip Coffee Maker"
                    data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_180x.jpg?v=1531907368 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_360x.jpg?v=1531907368 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_470x.jpg?v=1531907368 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_600x.jpg?v=1531907368 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_770x.jpg?v=1531907368 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_970x.jpg?v=1531907368 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1060x.jpg?v=1531907368 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1280x.jpg?v=1531907368 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1512x.jpg?v=1531907368 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1728x.jpg?v=1531907368 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_2048x.jpg?v=1531907368 2048w"
                    sizes="60px"
                    srcset="
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_180x.jpg?v=1531907368   180w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_360x.jpg?v=1531907368   360w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_470x.jpg?v=1531907368   470w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_600x.jpg?v=1531907368   600w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_770x.jpg?v=1531907368   770w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_970x.jpg?v=1531907368   970w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1060x.jpg?v=1531907368 1060w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1280x.jpg?v=1531907368 1280w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1512x.jpg?v=1531907368 1512w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_1728x.jpg?v=1531907368 1728w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_2048x.jpg?v=1531907368 2048w
                    "
                  />
                  <noscript>
                    <img
                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_480x480@2x.jpg?v=1531907368"
                      alt="Drip Coffee Maker"
                      class="dt-sc-noscript-image"
                    />
                  </noscript>
                  <span class="title">Drip Coffee Maker</span>
                </a>
                <p>
                  <span class="purchased">Someone recently bought</span>from
                  <span class="location">Chennai, India</span>
                  <span class="timing">19 minutes ago</span>
                </p>
                <a href="javascript:void(0)" title="Close" class="dT_close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <path
                      d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="product-data active">
                <a href="https://dt-kitchen-utensils.myshopify.com/products/multifunction-rice-cooker">
                  <img
                    class="lazyautosizes ls-is-cached lazyloaded"
                    src="./News – Cooksy _ Kitchen Utensils_files/loading.gif"
                    data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                    data-aspectratio="0.8514664143803217"
                    data-sizes="auto"
                    alt="Multifunction Rice Cooker"
                    data-srcset="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_180x.jpg?v=1531907363 180w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_360x.jpg?v=1531907363 360w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_470x.jpg?v=1531907363 470w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_600x.jpg?v=1531907363 600w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_770x.jpg?v=1531907363 770w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_970x.jpg?v=1531907363 970w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1060x.jpg?v=1531907363 1060w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1280x.jpg?v=1531907363 1280w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1512x.jpg?v=1531907363 1512w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1728x.jpg?v=1531907363 1728w, //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_2048x.jpg?v=1531907363 2048w"
                    sizes="60px"
                    srcset="
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_180x.jpg?v=1531907363   180w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_360x.jpg?v=1531907363   360w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_470x.jpg?v=1531907363   470w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_600x.jpg?v=1531907363   600w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_770x.jpg?v=1531907363   770w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_970x.jpg?v=1531907363   970w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1060x.jpg?v=1531907363 1060w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1280x.jpg?v=1531907363 1280w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1512x.jpg?v=1531907363 1512w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_1728x.jpg?v=1531907363 1728w,
                      //dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_2048x.jpg?v=1531907363 2048w
                    "
                  />
                  <noscript>
                    <img
                      src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-48_84b5b1d8-b7c3-4d90-bdf1-b9e802be8ef9_480x480@2x.jpg?v=1531907363"
                      alt="Multifunction Rice Cooker"
                      class="dt-sc-noscript-image"
                    />
                  </noscript>
                  <span class="title">Multifunction Rice Cooker</span>
                </a>
                <p>
                  <span class="purchased">Someone recently bought</span>from
                  <span class="location">Sydney, Australia</span>
                  <span class="timing">27 minutes ago</span>
                </p>
                <a href="javascript:void(0)" title="Close" class="dT_close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <path
                      d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="dT_LoadingOverlay"></div>
          <div class="dT_collectionOverlay"></div>

          {/* <!-- /snippets/ajax-cart-template.liquid --> */}
          <a
            id="to-top"
            href="https://dt-kitchen-utensils.myshopify.com/blogs/news#news"
            class="dt-sc-to-top"
            style={{ display: "none" }}
          >
            {/* <!--   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xmlSpace="preserve">
    <path d="M263.432,3.136c-4.16-4.171-10.914-4.179-15.085-0.019c-0.006,0.006-0.013,0.013-0.019,0.019
             l-192,192c-4.093,4.237-3.975,10.99,0.262,15.083c4.134,3.992,10.687,3.992,14.82,0L245.213,36.416v464.917
             c0,5.891,4.776,10.667,10.667,10.667c5.891,0,10.667-4.776,10.667-10.667V36.416l173.781,173.781
             c4.093,4.237,10.845,4.355,15.083,0.262c4.237-4.093,4.354-10.845,0.262-15.083c-0.086-0.089-0.173-0.176-0.262-0.262L263.432,3.136
             z"/>
    <path d="M447.88,213.333c-2.831,0.005-5.548-1.115-7.552-3.115L255.88,25.749L71.432,210.219c-4.237,4.093-10.99,3.975-15.083-0.262
             c-3.992-4.134-3.992-10.687,0-14.82l192-192c4.165-4.164,10.917-4.164,15.083,0l192,192c4.159,4.172,4.149,10.926-0.024,15.085
             C453.409,212.214,450.702,213.333,447.88,213.333z"/>
    <path d="M255.88,512c-5.891,0-10.667-4.776-10.667-10.667V10.667C245.213,4.776,249.989,0,255.88,0
             c5.891,0,10.667,4.776,10.667,10.667v490.667C266.546,507.224,261.771,512,255.88,512z"/>
  </svg> --> */}
            <i class="fa fa-long-arrow-up"></i>
          </a>
        </div>
      </div>

      <div
        id="eJOY__extension_root"
        class="eJOY__extension_root_class"
        style={{ all: "unset" }}
      >
        <template shadowrootmode="open">
          <div id="eJOY__extension_shadow">
            <div class="wrapperEjoy">
              <div class="container"></div>
              <span
                style={{
                  position: "absolute",
                  top: "1px",
                  left: "1px",
                  opacity: "0",
                  pointerEvents: "none",
                  backgroundColor: "rgb(229, 56, 56)",
                  border: "1px solid rgb(229, 56, 56)",
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "center",
                  color: "rgb(255, 255, 255)",
                  zIndex: "2147483647",
                  fontSize: "8px",
                  minWidth: "12px",
                  minHeight: "12px",
                  lineHeight: "11px",
                  alignItems: "center",
                }}
              >
                1
              </span>
              <span
                style={{
                  position: "absolute",
                  top: "1px",
                  opacity: "0",
                  pointerEvents: "none",
                  backgroundColor: "rgba(2, 2, 2, 0.9)",
                  borderRadius: "4px",
                  padding: "4px 10px",
                  display: "flex",
                  minWidth: "100px",
                  justifyContent: "center",
                  color: "rgb(255, 255, 255)",
                  zIndex: "2147483647",
                  fontSize: "12px",
                  minHeight: "12px",
                  lineHeight: "16px",
                  alignItems: "center",
                }}
              ></span>
            </div>
          </div>
        </template>
      </div>
      <CpLoading {...props}></CpLoading>
    </React.Fragment>
  );
}

export default PBlog;
