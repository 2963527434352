import React, { useEffect } from "react";
// import CpHeader from "../component/cp_header"
// import CpLoading from "../component/cp_loading"
function Phome(props) {
  useEffect(() => {});
  return (
    <React.Fragment>
      <div
        tabIndex="-1"
        aria-hidden="true"
        id="web-pixels-manager-sandbox-container"
        data-shopify-privacy="exclude"
        style={{
          height: "0px",
          width: "0px",
          position: "fixed",
          visibility: "hidden",
          overflow: "hidden",
          zIndex: -100,
          margin: "0px",
          padding: "0px",
          border: "0px",
        }}
      ></div>

      <div class="mobile-menu" data-menu="dt-main-menu">
        <ul class="dt-sc-list-inline dt-desktop-menu" data-menu="dt-main-menu">
          <li class="close-nav"></li>

          <li class="0 text-menu top-level-link dt-sc-child active">
            <a href="" class="dt-sc-nav-link dropdown">
              Home
            </a>
          </li>
          <li class="2 promo_image top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
            <a href="" class="mega-menu dt-sc-nav-link dropdown">
              Bộ Sưu Tập <span class="dt-sc-caret"></span>
            </a>

            <div
              class="sub-menu-block is-hidden"
              style={{ width: "1349px", left: "-393px" }}
            >
              <div class="go-back">
                <a href="javascript:void(0);"></a>
              </div>
              <div class="see-all"></div>
              <div
                class="dt-sc-dropdown-menu dt-sc--main-menu--mega dt-sc_main-menu--has-links"
                id="collection-2-dt-sc-menu"
              >
                <ul class="sub-menu-lists dt-sc-column four-column">
                  <li class="">
                    <h5>
                      <a href="">REFRIGERATORS</a>
                    </h5>
                    <ul>
                      <li class="">
                        <a href="">Haier</a>
                      </li>
                      <li class="">
                        <a href="">LG</a>
                      </li>
                      <li class="">
                        <a href="">Samsung</a>
                      </li>
                      <li class="">
                        <a href="">Godrej M</a>
                      </li>
                      <li class="">
                        <a href="">itashi</a>
                      </li>
                      <li class="">
                        <a href="">Bosch</a>
                      </li>
                      <li class="">
                        <a href="">Kelvinator</a>
                      </li>
                      <li class="">
                        <a href="">Kenstar</a>
                      </li>
                    </ul>
                  </li>
                  <li class="">
                    <h5>
                      <a href="">DISHWASHERS</a>
                    </h5>
                    <ul>
                      <li class="">
                        <a href="">Siemens</a>
                      </li>
                      <li class="">
                        <a href="">Glassiano</a>
                      </li>
                      <li class="">
                        <a href="">Kraft Italy</a>
                      </li>
                      <li class="">
                        <a href="">Whirlpool</a>
                      </li>
                      <li class="">
                        <a href="">Lithara</a>
                      </li>
                      <li class="">
                        <a href="">IFB</a>
                      </li>
                      <li class="">
                        <a href="">Bosch</a>
                      </li>
                      <li class="">
                        <a href="">Frigidaire</a>
                      </li>
                    </ul>
                  </li>
                  <li class="">
                    <h5>
                      <a href="">FOOD PROCESSORS</a>
                    </h5>
                    <ul>
                      <li class="">
                        <a href="">Kenstar</a>
                      </li>
                      <li class="">
                        <a href="">Usha</a>
                      </li>
                      <li class="">
                        <a href="">Prestige</a>
                      </li>
                      <li class="">
                        <a href="">Singer</a>
                      </li>
                      <li class="">
                        <a href="">Philips</a>
                      </li>
                      <li class="">
                        <a href="">Inalsa</a>
                      </li>
                      <li class="">
                        <a href="">Bajaj</a>
                      </li>
                      <li class="">
                        <a href="">Maggi RIO</a>
                      </li>
                    </ul>
                  </li>

                  <li class="dt-sc-menu-image-with-text top-level-link">
                    <div class="dt-sc-mega_menu text-center">
                      <img
                        class="lazyload"
                        src="./assets/imgs/loading.gif"
                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1.jpg?v=1613763304"
                        data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                        data-aspectratio="0.7477744807121661"
                        data-sizes="auto"
                        alt=""
                      />
                      <noscript>
                        <img
                          class="dt-sc-noscript-image"
                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1_480x480@2x.jpg?v=1613763304"
                          alt=""
                        />
                      </noscript>
                      <div class="dt-sc-details"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li class="0 megaProduct top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
            <a href="s" class="mega-menu dt-sc-nav-link dropdown">
              Của Hàng <span class="dt-sc-caret"></span>
            </a>

            <div
              class="sub-menu-block is-hidden"
              style={{ width: "1349px", left: "-505px" }}
            >
              <div class="go-back">
                <a href="javascript:void(0);"></a>
              </div>
              <div class="see-all"></div>
              <div
                class="dt-sc-dropdown-menu dt-sc--main-menu--mega"
                id="shop-3-dt-sc-menu"
              >
                <ul class="sub-menu-lists dt-sc-column four-column">
                  <li class="dt-sc-menu-product">
                    <div class="dt-sc-menu-product__item">
                      <div class="dt-sc-menu-product_item-image">
                        <a href="">
                          <img
                            class="lazyload"
                            src="./assets/imgs/loading.gif"
                            data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                            data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                            data-aspectratio="0.8514664143803217"
                            data-sizes="auto"
                            alt="400-Watt Juicer (Black)"
                          />
                          <noscript>
                            <img
                              src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                              alt="400-Watt Juicer (Black)"
                              class="dt-sc-noscript-image"
                            />
                          </noscript>
                        </a>
                      </div>
                      <div class="dt-sc-menu-product_item-info">
                        <a class="dt-sc-product__title" href="">
                          400-Watt Juicer (Black)
                        </a>
                        <span class="dt-sc-price">
                          <span class="money">$245.00</span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li class="dt-sc-menu-product">
                    <div class="dt-sc-menu-product__item">
                      <div class="dt-sc-menu-product_item-image">
                        <a href=" ">
                          <img
                            class="lazyload"
                            src="./assets/imgs/loading.gif"
                            data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12.jpg?v=1531907350"
                            data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                            data-aspectratio="0.8514664143803217"
                            data-sizes="auto"
                            alt="Blender with Turbo Button"
                          />
                          <noscript>
                            <img
                              src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12_480x480@2x.jpg?v=1531907350"
                              alt="Blender with Turbo Button"
                              class="dt-sc-noscript-image"
                            />
                          </noscript>
                        </a>
                      </div>
                      <div class="dt-sc-menu-product_item-info">
                        <a class="dt-sc-product__title" href=" ">
                          Blender with Turbo Button
                        </a>
                        <span class="dt-sc-price">
                          <span class="money">$329.00</span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li class="dt-sc-menu-product">
                    <div class="dt-sc-menu-product__item">
                      <div class="dt-sc-menu-product_item-image">
                        <a href=" ">
                          <img
                            class="lazyload"
                            src="./assets/imgs/loading.gif"
                            data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62.jpg?v=1531907360"
                            data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                            data-aspectratio="0.8514664143803217"
                            data-sizes="auto"
                            alt="Túi Đựng Rác"
                          />
                          <noscript>
                            <img
                              src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_480x480@2x.jpg?v=1531907360"
                              alt="Túi Đựng Rác"
                              class="dt-sc-noscript-image"
                            />
                          </noscript>
                        </a>
                      </div>
                      <div class="dt-sc-menu-product_item-info">
                        <a class="dt-sc-product__title" href=" ">
                          Túi Đựng Rác
                        </a>
                        <span class="dt-sc-price">
                          <span class="money">$530.00</span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li class="dt-sc-menu-product">
                    <div class="dt-sc-menu-product__item">
                      <div class="dt-sc-menu-product_item-image">
                        <a href=" ">
                          <img
                            class="lazyload"
                            src="./assets/imgs/loading.gif"
                            data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15.jpg?v=1531907368"
                            data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                            data-aspectratio="0.8514664143803217"
                            data-sizes="auto"
                            alt="Drip Coffee Maker"
                          />
                          <noscript>
                            <img
                              src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_480x480@2x.jpg?v=1531907368"
                              alt="Drip Coffee Maker"
                              class="dt-sc-noscript-image"
                            />
                          </noscript>
                        </a>
                      </div>
                      <div class="dt-sc-menu-product_item-info">
                        <a class="dt-sc-product__title" href=" ">
                          Drip Coffee Maker
                        </a>
                        <span class="dt-sc-price">
                          <span class="money">$789.00</span>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li class="0 text-menu top-level-link dt-sc-child">
            <a href=" " class="dt-sc-nav-link dropdown">
              Cửa Hàng lớn
            </a>
          </li>

          <li class="0 text-menu top-level-link dt-sc-child">
            <a href=" " class="dt-sc-nav-link dropdown">
              Cửa Hàng nhỏ
            </a>
          </li>

          <li class="0 text-menu top-level-link dt-sc-child">
            <a href=" " class="dt-sc-nav-link dropdown">
              Appliance
            </a>
          </li>

          <li class="1 text-menu top-level-link dt-sc-child menu-item-has-children">
            <a href=" " class="dt-sc-nav-link dropdown">
              Pages <span class="dt-sc-caret"></span>
            </a>

            <div class="sub-menu-block is-hidden">
              <div class="go-back">
                <a href="javascript:void(0);"></a>
              </div>
              <div class="see-all"></div>
              <div
                class="dt-sc-dropdown-menu dt-sc_main-menu--has-links"
                id="pages-7-dt-sc-menu"
              >
                <ul class="sub-menu-lists">
                  <li class=" ">
                    <a href=" ">About us</a>
                  </li>
                  <li class=" ">
                    <a href=" ">Contact us</a>
                  </li>
                  <li class=" ">
                    <a href=" ">Blog</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="mobile-menu-overlay"></div>

      <div className="off-canvas--viewport">
        <div id="CartDrawer" className="drawer drawer--right">
          <div className="drawer__header">
            <span className="drawer__close js-drawer-close dt-sc-btn close-icon"></span>
            <h4>Giỏ hàng</h4>
          </div>
          <div id="CartContainer"></div>
        </div>

        <div id="shopify-section-header" className="shopify-section">
          <div
            data-section-id="header"
            data-section-type="header-section"
            data-header-section=""
          >
            {/* header ở đây */}
            <header id="header">
              <section
                className="text-center announcement-bar hide-mobile"
                style={{ height: "40px" }}
              />

              <section className="dt-sc-header-top-bar">
                <div className="container-fluid spacing_enabled">
                  <div className="row">
                    <div className="dt-sc-flex-space-between header-top">
                      <ul className="dt-sc-list-inline header-contact">
                        <li>
                          <a href="mailto:kencoshop2024@gmail.com">
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                            kencoshop2024@gmail.com
                          </a>
                        </li>
                        <li>
                          <a href="tel:0708098515">
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            070 809 8515
                          </a>
                        </li>
                      </ul>

                      <div className="dt-sc-list-inline"></div>

                      <div className="site-header__links dt-sc-flex">
                        <a
                          href=" "
                          className="site-header__icon site-header__account"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M46.8,57c0.9,0,1.8,0,2.7,0c12,0,19.2-13.2,20.7-23.7c0.3-1.5,0.6-3.3,0.6-5.1C70.9,15.5,62.8,5,49.5,5
		S28.2,15.5,28.2,28.2C28.2,39.6,34.5,54.6,46.8,57z"
                              ></path>
                              <path
                                d="M64.3,60.6c-3.6-0.9-4.8-0.6-6.9,5.1l-7.2,20.7l-7.5-20.7c-2.1-5.7-3.3-6-6.9-5.1c-15.3,3.3-24,3.9-23.7,20.1
		v10.2c0,2.6,1.5,4,3,4c0,0,0,0,0,0h69.9v0c1.6,0,3.1-1.4,3.1-4.1V80.7C88.3,64.5,79.6,63.9,64.3,60.6z"
                              ></path>
                            </g>
                          </svg>

                          <span className="icon__fallback-text">Tài Khoản</span>
                        </a>

                        <form
                          method="post"
                          action=" "
                          id="currency_form"
                          accept-charset="UTF-8"
                          className="shopify-currency-form"
                          enctype="multipart/form-data"
                        >
                          <div className="custom-select">
                            <select
                              name="currency"
                              data-select2-id="select2-data-1-tws1"
                              tabIndex="-1"
                              className="select2-hidden-accessible"
                              aria-hidden="true"
                            >
                              <option
                                selected={true}
                                data-select2-id="select2-data-3-est9"
                              >
                                VNĐ
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--classic"
                              data-select2-id="select2-data-2-ocpn"
                              dir="ltr"
                              style={{
                                width: "55px",
                              }}
                            >
                              {/* <span className="selection">
                                <span
                                  aria-controls="select2-currency-3h-container"
                                  aria-disabled="false"
                                  aria-expanded="false"
                                  aria-haspopup="true"
                                  aria-labelledby="select2-currency-3h-container"
                                  className="select2-selection select2-selection--single"
                                  role="combobox"
                                  tabIndex="0"
                                >
                                  <span
                                    aria-readonly="true"
                                    className="select2-selection__rendered"
                                    id="select2-currency-3h-container"
                                    role="textbox"
                                    title="VNĐ"
                                  >
                                    VNĐ
                                  </span>
                                  <span
                                    className="select2-selection__arrow"
                                    role="presentation"
                                  >
                                    <b role="presentation" />
                                  </span>
                                </span>
                              </span> */}
                              <span
                                aria-hidden="true"
                                className="dropdown-wrapper"
                              />
                            </span>
                          </div>
                        </form>

                        <a
                          href=" "
                          className="site-header__icon site-header__wishlist"
                        >
                          <svg
                            className="heart-filled"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                            xmlSpace="preserve"
                          >
                            <path
                              d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                            ></path>
                          </svg>

                          <span
                            className="count dt-wishlist-cnt"
                            style={{ display: "none" }}
                          >
                            {" "}
                            0
                          </span>
                        </a>

                        <a
                          href=" "
                          className="site-header__cart-toggle js-drawer-open-right dT_MiniCartModelBtn"
                          aria-controls="CartDrawer"
                          aria-expanded="false"
                          id="cart-number"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M94.4,22.7c-0.4-0.1-0.8-0.2-1.2-0.2H77.5h-7.8H46.1h-7.8h-9.8L25,12.8c-2.1-6-7.8-10-14.2-9.9H6.8c-2.2,0-3.9,1.8-3.9,3.9
		s1.8,3.9,3.9,3.9h3.9c3,0,5.7,1.9,6.7,4.7l15.2,42.4c1.7,4.7,6.1,7.8,11.1,7.8h32c5.1,0,9.6-3.2,11.2-8l10-30
		C97.6,25.6,96.5,23.4,94.4,22.7z"
                              ></path>
                              <circle cx="42.1" cy="85.3" r="11.8"></circle>
                              <circle cx="73.5" cy="85.3" r="11.8"></circle>
                            </g>
                          </svg>

                          <span className="icon__fallback-text">Giỏ Hàng</span>
                          <div
                            id="CartCount"
                            data-cart_item_count="0"
                            data-show_cnt_zero="yes"
                            className="site-header__cart-count xhide"
                          >
                            <span className="count CartCount">0</span>
                            <span className="cart-words">items</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="dt-sc-header-logo-sec">
                <div className="container-fluid spacing_enabled">
                  <div className="row">
                    <div className="logo-alignment dt-sc-menu_bottom">
                      <div id="sticky-position" className="scroll-nav">
                        <nav
                          className="dt-sc-flex-space-between logo-search"
                          id="AccessibleNav"
                          style={{ top: "50px" }}
                        >
                          <div className="header_search">
                            <div className="dt-sc-search-form">
                              <div
                                id="SearchDrawer"
                                className="dT_ProductProactiveSearch"
                              >
                                <form action=" " method="get">
                                  <input
                                    type="search"
                                    readOnly
                                    id="SearchInput"
                                    name="q"
                                    placeholder="Search"
                                    aria-label="Search"
                                    autoComplete="off"
                                    className="search-bar__input"
                                  />
                                  <button type="submit" className="dt-sc-btn">
                                    <svg
                                      version="1.1"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 100"
                                      xmlSpace="preserve"
                                    >
                                      <path
                                        d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    className="dt-sc-btn close dT_SearchClose"
                                    style={{ display: "none" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 100"
                                      xmlSpace="preserve"
                                    >
                                      <path
                                        d="M57,50l35.2-35.2c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L50,43L14.8,7.7c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7
	L43,50L7.7,85.2c-1.9,1.9-1.9,5.1,0,7c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5L50,57l35.2,35.2c1,1,2.2,1.5,3.5,1.5s2.5-0.5,3.5-1.5
	c1.9-1.9,1.9-5.1,0-7L57,50z"
                                      ></path>
                                    </svg>
                                  </button>
                                </form>
                                <div
                                  className="dT_PredictiveSearchResult_Section"
                                  style={{ display: "none" }}
                                >
                                  <ul className="dt-sc-ProductSugesstions"></ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="logo text-center">
                            <a href=" ">
                              <img
                                src="./assets/imgs/logo_1_300x300.png"
                                alt="Cooksy | Kitchen Utensils"
                                style={{ maxWidth: "250px" }}
                              />
                            </a>
                          </div>

                          <div className="header-shipping-details">
                            <div className="header-shipping-icons"></div>
                            <div className="header-shipping-icons">
                              <div className="ship-icon">
                                <img
                                  src="./assets/imgs/icons-6_40x40.png"
                                  alt="BIG MONEY SAVING"
                                  title="BIG MONEY SAVING"
                                />
                              </div>

                              <div className="ship-detail">
                                <h6>TIẾT KIỆM</h6>
                                <p>Đảm Bảo Hoàn Tiền</p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="mobile-nav-container mobile-nav-offcanvas-right dt-header-menu"
                            data-menu="dt-main-menu"
                          >
                            <div
                              className="menu-trigger"
                              data-menu="dt-main-menu"
                            >
                              <i className="menu-trigger-icon"></i>{" "}
                              <span>Menu</span>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <menu
                className="sticky-header sticky_remove_on_mobile"
                id="sticky-header"
              >
                <div className="container-fluid spacing_enabled">
                  <nav
                    id="AccessibleNav"
                    role="navigation"
                    className="row dt-sc-flex-space-between"
                    data-menu="dt-main-menu"
                  >
                    <div>
                      <ul
                        className="dt-sc-list-inline dt-desktop-menu dt-nav"
                        data-menu="dt-main-menu"
                      >
                        <li className="close-nav"></li>

                        <li className="0 text-menu top-level-link dt-sc-child active">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Trang Chủ
                          </a>
                        </li>

                        <li className="2 promo_image top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                          <a
                            href=" "
                            className="mega-menu dt-sc-nav-link dropdown"
                          >
                            Bộ sưu tập <span className="dt-sc-caret"></span>
                          </a>

                          <div
                            className="sub-menu-block is-hidden"
                            style={{ width: "1349px", left: "-393px" }}
                          >
                            <div className="see-all"></div>
                            <div
                              className="dt-sc-dropdown-menu dt-sc--main-menu--mega dt-sc_main-menu--has-links"
                              id="collection-2-dt-sc-menu"
                            >
                              <ul className="sub-menu-lists dt-sc-column four-column">
                                {/* Phân loại đồ dùng 1 */}
                                <li className="">
                                  <h5>
                                    <a href=" ">
                                      DỤNG CỤ ĂN UỐNG BẰNG NHỰA, GỖ VÀ SINH HỌC
                                    </a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">Muỗng</a>
                                    </li>
                                    <li>
                                      <a href=" ">Muỗng gỗ</a>
                                    </li>
                                    <li>
                                      <a href=" ">Muỗng sinh học từ cà phê</a>
                                    </li>
                                    <li>
                                      <a href=" ">Muỗng bã mía</a>
                                    </li>
                                    <li>
                                      <a href=" ">
                                        Muỗng nhựa có và không bọc màng opp
                                      </a>
                                    </li>
                                  </ul>
                                </li>

                                <li className="">
                                  <h5>
                                    <a href=" ">TÚI VÀ BAO BÌ</a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">
                                        Túi trà sữa 1 ngăn và 2 ngăn
                                      </a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Túi zip tự hủy sinh học</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Túi nilon tự hủy</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Túi rác sinh học</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">
                                        Túi rác tiểu/trung/đại/cực đại
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="">
                                  <h5>
                                    <a href=" ">FOOD PROCESSORS</a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">Kenstar</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Usha</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Prestige</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Singer</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Philips</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Inalsa</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Bajaj</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Maggi RIO</a>
                                    </li>
                                  </ul>
                                </li>

                                <li className="dt-sc-menu-image-with-text top-level-link">
                                  <div className="dt-sc-mega_menu text-center">
                                    <img
                                      className="lazyload"
                                      src="./assets/imgs/loading.gif"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1.jpg?v=1613763304"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="0.7477744807121661"
                                      data-sizes="auto"
                                      alt=""
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1_480x480@2x.jpg?v=1613763304"
                                        className="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                    <div className="dt-sc-details"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        <li className="0 megaProduct top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                          <a
                            href=" "
                            className="mega-menu dt-sc-nav-link dropdown"
                          >
                            Cửa Hàng <span className="dt-sc-caret"></span>
                          </a>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a
                            href="https://vn.shp.ee/svb2aXE"
                            className="dt-sc-nav-link dropdown"
                          >
                            Nhà Hàng
                          </a>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Quán Ăn
                          </a>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Appliance
                          </a>
                        </li>

                        <li className="1 text-menu top-level-link dt-sc-child menu-item-has-children">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Pages <span className="dt-sc-caret"></span>
                          </a>

                          <div className="sub-menu-block is-hidden">
                            <div className="go-back"></div>
                            <div className="see-all"></div>
                            <div
                              className="dt-sc-dropdown-menu dt-sc_main-menu--has-links"
                              id="pages-7-dt-sc-menu"
                            >
                              <ul className="sub-menu-lists">
                                <li className=" ">
                                  <a href=" ">About us</a>
                                </li>
                                <li className=" ">
                                  <a href=" ">Contact us</a>
                                </li>
                                <li className=" ">
                                  <a href=" ">Blog</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </menu>
              <menu className="sticky-header-active" id="sticky-header-active">
                <div className="container-fluid spacing_enabled">
                  <nav
                    id="AccessibleNav"
                    role="navigation"
                    className="row dt-sc-flex-space-between"
                    data-menu="dt-main-menu"
                  >
                    <div>
                      <ul
                        className="dt-sc-list-inline dt-desktop-menu dt-nav"
                        data-menu="dt-main-menu"
                      >
                        <li className="close-nav"></li>

                        <li className="0 text-menu top-level-link dt-sc-child active">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Home
                          </a>
                        </li>

                        <li className="2 promo_image top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                          <a
                            href=" "
                            className="mega-menu dt-sc-nav-link dropdown"
                          >
                            Bộ sưu tập <span className="dt-sc-caret"></span>
                          </a>

                          <div
                            className="sub-menu-block is-hidden"
                            style={{ width: "1349px", left: "-393px" }}
                          >
                            <div className="go-back"></div>
                            <div className="see-all"></div>
                            <div
                              className="dt-sc-dropdown-menu dt-sc--main-menu--mega dt-sc_main-menu--has-links"
                              id="collection-2-dt-sc-menu"
                            >
                              <ul className="sub-menu-lists dt-sc-column four-column">
                                <li className="">
                                  <h5>
                                    <a href=" ">REFRIGERATORS</a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">Haier</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">LG</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Samsung</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Godrej M</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">itashi</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Bosch</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Kelvinator</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Kenstar</a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="">
                                  <h5>
                                    <a href=" ">DISHWASHERS</a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">Siemens</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Glassiano</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Kraft Italy</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Whirlpool</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Lithara</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">IFB</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Bosch</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Frigidaire</a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="">
                                  <h5>
                                    <a href=" ">FOOD PROCESSORS</a>
                                  </h5>
                                  <ul>
                                    <li className="">
                                      <a href=" ">Kenstar</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Usha</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Prestige</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Singer</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Philips</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Inalsa</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Bajaj</a>
                                    </li>
                                    <li className="">
                                      <a href=" ">Maggi RIO</a>
                                    </li>
                                  </ul>
                                </li>

                                <li className="dt-sc-menu-image-with-text top-level-link">
                                  <div className="dt-sc-mega_menu text-center">
                                    <img
                                      className="lazyload"
                                      src="./assets/imgs/loading.gif"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1.jpg?v=1613763304"
                                      data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                      data-aspectratio="0.7477744807121661"
                                      data-sizes="auto"
                                      alt=""
                                    />
                                    <noscript>
                                      <img
                                        src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/menu-3-1_480x480@2x.jpg?v=1613763304"
                                        className="dt-sc-noscript-image"
                                      />
                                    </noscript>
                                    <div className="dt-sc-details"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        <li className="0 megaProduct top-level-link has-mega-menu dt-sc-primary menu-item-has-children">
                          <a
                            href=" "
                            className="mega-menu dt-sc-nav-link dropdown"
                          >
                            Cửa Hàng <span className="dt-sc-caret"></span>
                          </a>

                          <div
                            className="sub-menu-block is-hidden"
                            style={{ width: "1349px", left: "-505px" }}
                          >
                            <div className="go-back"></div>
                            <div className="see-all"></div>
                            <div
                              className="dt-sc-dropdown-menu dt-sc--main-menu--mega"
                              id="shop-3-dt-sc-menu"
                            >
                              <ul className="sub-menu-lists dt-sc-column four-column">
                                <li className="dt-sc-menu-product">
                                  <div className="dt-sc-menu-product__item">
                                    <div className="dt-sc-menu-product_item-image">
                                      <a href=" ">
                                        <img
                                          className="lazyload"
                                          src="./assets/imgs/loading.gif"
                                          data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                          data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                          data-aspectratio="0.8514664143803217"
                                          data-sizes="auto"
                                          alt="400-Watt Juicer (Black)"
                                        />
                                        <noscript>
                                          <img
                                            src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                            alt="400-Watt Juicer (Black)"
                                            className="dt-sc-noscript-image"
                                          />
                                        </noscript>
                                      </a>
                                    </div>
                                    <div className="dt-sc-menu-product_item-info">
                                      <a
                                        className="dt-sc-product__title"
                                        href=""
                                      >
                                        400-Watt Juicer (Black)
                                      </a>
                                      <span className="dt-sc-price">
                                        <span className="money">$245.00</span>
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li className="dt-sc-menu-product">
                                  <div className="dt-sc-menu-product__item">
                                    <div className="dt-sc-menu-product_item-image">
                                      <a href=" ">
                                        <img
                                          className="lazyload"
                                          src="./assets/imgs/loading.gif"
                                          data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12.jpg?v=1531907350"
                                          data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                          data-aspectratio="0.8514664143803217"
                                          data-sizes="auto"
                                          alt="Blender with Turbo Button"
                                        />
                                        <noscript>
                                          <img
                                            src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-12_480x480@2x.jpg?v=1531907350"
                                            alt="Blender with Turbo Button"
                                            className="dt-sc-noscript-image"
                                          />
                                        </noscript>
                                      </a>
                                    </div>
                                    <div className="dt-sc-menu-product_item-info">
                                      <a
                                        className="dt-sc-product__title"
                                        href=" "
                                      >
                                        Blender with Turbo Button
                                      </a>
                                      {/* <span className="dt-sc-price">
                                        <span className="money">$329.00</span>
                                      </span> */}
                                    </div>
                                  </div>
                                </li>

                                <li className="dt-sc-menu-product">
                                  <div className="dt-sc-menu-product__item">
                                    <div className="dt-sc-menu-product_item-image">
                                      <a href=" ">
                                        <img
                                          className="lazyload"
                                          src="./assets/imgs/loading.gif"
                                          data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62.jpg?v=1531907360"
                                          data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                          data-aspectratio="0.8514664143803217"
                                          data-sizes="auto"
                                          alt="Túi Đựng Rác"
                                        />
                                        <noscript>
                                          <img
                                            src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_480x480@2x.jpg?v=1531907360"
                                            alt="Túi Đựng Rác"
                                            className="dt-sc-noscript-image"
                                          />
                                        </noscript>
                                      </a>
                                    </div>
                                    <div className="dt-sc-menu-product_item-info">
                                      <a
                                        className="dt-sc-product__title"
                                        href=" "
                                      >
                                        Túi Đựng Rác
                                      </a>
                                      <span className="dt-sc-price">
                                        <span className="money">$530.00</span>
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li className="dt-sc-menu-product">
                                  <div className="dt-sc-menu-product__item">
                                    <div className="dt-sc-menu-product_item-image">
                                      <a href=" ">
                                        <img
                                          className="lazyload"
                                          src="./assets/imgs/loading.gif"
                                          data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15.jpg?v=1531907368"
                                          data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                          data-aspectratio="0.8514664143803217"
                                          data-sizes="auto"
                                          alt="Drip Coffee Maker"
                                        />
                                        <noscript>
                                          <img
                                            src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-4_1771ba76-03d0-42f0-ae19-c003c0365e15_480x480@2x.jpg?v=1531907368"
                                            alt="Drip Coffee Maker"
                                            className="dt-sc-noscript-image"
                                          />
                                        </noscript>
                                      </a>
                                    </div>
                                    <div className="dt-sc-menu-product_item-info">
                                      <a
                                        className="dt-sc-product__title"
                                        href=" "
                                      >
                                        Drip Coffee Maker
                                      </a>
                                      <span className="dt-sc-price">
                                        <span className="money">$789.00</span>
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a
                            href="https://vn.shp.ee/svb2aXE"
                            className="dt-sc-nav-link dropdown"
                          >
                            Nhà Hàng
                          </a>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Cửa Hàng Nhỏ
                          </a>
                        </li>

                        <li className="0 text-menu top-level-link dt-sc-child">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Appliance
                          </a>
                        </li>

                        <li className="1 text-menu top-level-link dt-sc-child menu-item-has-children">
                          <a href=" " className="dt-sc-nav-link dropdown">
                            Pages <span className="dt-sc-caret"></span>
                          </a>

                          <div className="sub-menu-block is-hidden">
                            <div className="go-back"></div>
                            <div className="see-all"></div>
                            <div
                              className="dt-sc-dropdown-menu dt-sc_main-menu--has-links"
                              id="pages-7-dt-sc-menu"
                            >
                              <ul className="sub-menu-lists">
                                <li className=" ">
                                  <a href=" ">Về chúng tôi</a>
                                </li>
                                <li className=" ">
                                  <a href=" ">Liên hệ</a>
                                </li>
                                <li className=" ">
                                  <a href=" ">Bài viết </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </menu>
            </header>
          </div>
        </div>

        <div className="clearfix"></div>

        <div className="shifter-page is-moved-by-drawer" id="container">
          <div className="container-fluid spacing_enabled">
            <div className="row">
              <div
                id="shopify-section-slideshow"
                className="shopify-section index-section home-slideshow-section"
              >
                <div
                  className="container-fluid spacing_enabled"
                  data-section-id="slideshow"
                  data-section-type="slideshow-section"
                  data-autoplay="false"
                  data-animation-speed="5000"
                >
                  <div className="row">
                    <div
                      className="dt-sc-swiper-slider swiper-container center-navigation inside_container"
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                    >
                      <div
                        id="home-slider-slideshow"
                        className="swiper-container-initialized swiper-container-horizontal swiper-container-autoheight"
                      >
                        <div
                          className="swiper-wrapper"
                          style={{
                            height: "550px",
                            transform: "translate3d(-1349px, 0px, 0px)",
                            transitionDuration: "0ms",
                          }}
                        >
                          <div
                            className="slider_style_3 slider-d0de749d-372d-463d-8424-9b02a3bac2c3-list swiper-slide swiper-slide-duplicate swiper-slide-prev"
                            data-swiper-slide-index="2"
                            style={{ width: "1349px" }}
                          >
                            <a href=" ">
                              <img
                                className="slide-img"
                                src="./assets/imgs/kitchen--slider-3.jpg"
                                alt=""
                              />
                            </a>

                            <div className="slider-content slider-content-bg text-center center-left">
                              <div className="slide_3">
                                <p
                                  className="slide-sub-heading-2"
                                  style={{ color: "#000000" }}
                                >
                                  KenCo
                                </p>

                                <h2
                                  className="slide-heading"
                                  style={{ color: "#000000", color: "#e51c26" }}
                                >
                                  Kitchen sets
                                </h2>

                                <div
                                  className="slide-text"
                                  style={{ color: "#000000", color: "#2c7b9d" }}
                                >
                                  Giảm giá <span>50</span>%
                                </div>

                                <div className="multiple-buttons">
                                  <a
                                    href=" "
                                    className="slide-button dt-sc-btn"
                                  >
                                    Mua Ngay
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="slider_style_1 slider-e63a61cb-cc8f-4ebd-9128-bbcfbe3a3638-list swiper-slide swiper-slide-active"
                            data-swiper-slide-index="0"
                            style={{ width: "1349px" }}
                          >
                            <a href="https://vn.shp.ee/svb2aXE">
                              <img
                                className="slide-img"
                                src="./assets/imgs/kitchen--slider-1.jpg"
                                alt=""
                              />
                            </a>

                            <div className="slider-content slider-content-bg text-start center-right">
                              <div className="slide_1">
                                <p
                                  className="slide-sub-heading-2"
                                  style={{ color: "#000000" }}
                                >
                                  KenCo
                                </p>

                                <h2
                                  className="slide-heading"
                                  style={{ color: "#000000", color: "#ffffff" }}
                                >
                                  Đồ Dùng Một Lần
                                </h2>

                                <div className="multiple-buttons">
                                  <a
                                    href="https://vn.shp.ee/svb2aXE"
                                    className="slide-button dt-sc-btn"
                                  >
                                    Mua Ngay
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="slider_style_3 slider-6722ef65-999b-41b2-8f9c-be510bd7be34-list swiper-slide swiper-slide-next"
                            data-swiper-slide-index="1"
                            style={{ width: "1349px" }}
                          >
                            <a href=" ">
                              <img
                                className="slide-img"
                                src="./assets/imgs/kitchen--slider-2.jpg"
                                alt=""
                              />
                            </a>

                            <div className="slider-content slider-content-bg text-center top-center">
                              <div className="slide_2">
                                <p
                                  className="slide-sub-heading-2"
                                  style={{ color: "#000000" }}
                                >
                                  Cooksy
                                </p>

                                <h2
                                  className="slide-heading"
                                  style={{ color: "#000000", color: "#ff7800" }}
                                >
                                  Kichen Appliances
                                </h2>

                                <div
                                  className="slide-text"
                                  style={{ color: "#000000", color: "#2c7b9d" }}
                                >
                                  UPTO <span>50</span>% OFF
                                </div>

                                <div className="multiple-buttons">
                                  <a
                                    href=" "
                                    className="slide-button dt-sc-btn"
                                  >
                                    Shop Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="slider_style_3 slider-d0de749d-372d-463d-8424-9b02a3bac2c3-list swiper-slide swiper-slide-duplicate-prev"
                            data-swiper-slide-index="2"
                            style={{ width: "1349px" }}
                          >
                            <a href=" ">
                              <img
                                className="slide-img"
                                src="./assets/imgs/kitchen--slider-3.jpg"
                                alt=""
                              />
                            </a>

                            <div className="slider-content slider-content-bg text-center center-left">
                              <div className="slide_3">
                                <p
                                  className="slide-sub-heading-2"
                                  style={{ color: "#000000" }}
                                >
                                  Cooksy
                                </p>

                                <h2
                                  className="slide-heading"
                                  style={{ color: "#000000", color: "#e51c26" }}
                                >
                                  Kitchen sets
                                </h2>

                                <div
                                  className="slide-text"
                                  style={{ color: "#000000", color: "#2c7b9d" }}
                                >
                                  FLAT <span>50</span>% OFF
                                </div>

                                <div className="multiple-buttons">
                                  <a
                                    href=" "
                                    className="slide-button dt-sc-btn"
                                  >
                                    Shop Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="slider_style_1 slider-e63a61cb-cc8f-4ebd-9128-bbcfbe3a3638-list swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                            data-swiper-slide-index="0"
                            style={{ width: "1349px" }}
                          >
                            <a href="https://vn.shp.ee/svb2aXE">
                              <img
                                className="slide-img"
                                src="./assets/imgs/kitchen--slider-1.jpg"
                                alt=""
                              />
                            </a>

                            <div className="slider-content slider-content-bg text-start center-right">
                              <div className="slide_1">
                                <p
                                  className="slide-sub-heading-2"
                                  style={{ color: "#000000" }}
                                >
                                  Cooksy
                                </p>

                                <h2
                                  className="slide-heading"
                                  style={{ color: "#000000", color: "#ffffff" }}
                                >
                                  Kitchen Utensils
                                </h2>

                                <div className="multiple-buttons">
                                  <a
                                    href="https://vn.shp.ee/svb2aXE"
                                    className="slide-button dt-sc-btn"
                                  >
                                    Shop Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slideshow-arrows swiper-arrows">
                          <div
                            className="swiper-button-next dt-sc-btn"
                            id="swiper-button-next-slideshow"
                            tabIndex="0"
                            role="button"
                            aria-label="Next slide"
                          ></div>
                          <div
                            className="swiper-button-prev dt-sc-btn"
                            id="swiper-button-prev-slideshow"
                            tabIndex="0"
                            role="button"
                            aria-label="Previous slide"
                          ></div>
                        </div>

                        <span
                          className="swiper-notification"
                          aria-live="assertive"
                          aria-atomic="true"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="enable_sidebar index-sidebar-left">
                <div
                  id="shopify-section-index-sidebar-menu"
                  className="shopify-section"
                >
                  <div className="filter-panel-menu">
                    <h5 className="sidebar_title">Sản Phẩm Nổi Bật</h5>
                    <div className="filter-panel" id="accordian">
                      <ul>
                        <li className="">
                          <a href=" ">Màng Bọc Thực Phẩm</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" "> </a>
                            </li>
                            <li>
                              <a href=" ">Size 4oz</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Màng Nhôm Thực Phẩm</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">Size 750ml</a>
                            </li>
                            <li>
                              <a href=" ">Size 1000ml</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Hộp Đựng Thực Phẩm</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Bao Tay Tiện Dụng</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Giấy Chuyên Dụng</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Hộp Bảo Quản Thực Phẩm</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Sản Phẩm Thân Thiện Môi Trường</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <a href=" ">Túi Bảo Quản Thực Phẩm</a>
                          <ul style={{ display: "none" }}>
                            <li>
                              <a href=" ">1 Ngăn</a>
                            </li>
                            <li>
                              <a href=" ">2 Ngăn</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  id="shopify-section-index-sidebar-promo"
                  className="shopify-section"
                >
                  <div className="widget index-sidebar-promo">
                    <a href=" ">
                      <img
                        className="lazyautosizes ls-is-cached lazyloaded"
                        src="./assets/imgs/img-1-2_fc2179e3-0dad-4927-830c-ef80827d8ea1.jpg"
                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-1-2_fc2179e3-0dad-4927-830c-ef80827d8ea1.jpg?v=1613763296"
                        data-aspectratio="0.713091922005571"
                        data-sizes="auto"
                        alt=""
                        sizes="302px"
                      />
                      <noscript>
                        <img
                          src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-1-2_fc2179e3-0dad-4927-830c-ef80827d8ea1_480x480@2x.jpg?v=1613763296"
                          className="dt-sc-noscript-image"
                        />
                      </noscript>
                    </a>
                    <div className="index-sidebar-promo_content_index-sidebar-promo"></div>
                  </div>
                </div>
                <div
                  id="shopify-section-index-sidebar-products"
                  className="shopify-section"
                >
                  <div className="widget index-sidebar-type-collection">
                    <h5 className="sidebar_title">Sản Phẩm Mới</h5>

                    <div className="dT_VProdWrapperOther">
                      <ul className="product-list-style">
                        <li
                          id="product-1478668943425"
                          className="grid-item product-grid-item"
                        >
                          {/* --------New product 1-------- */}
                          <div className="products">
                            <div className="product-container">
                              <a href=" " className="grid-link product-group">
                                <div className="image_group">
                                  <div className="ImageOverlayCa"></div>
                                  <div className="reveal">
                                    <span className="product-additional">
                                      <img
                                        src="./assets/imgs/Togiay.png"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt4_a7de3558-2729-43f5-811c-d65045db7a28_compact.jpg?v=1535710571"
                                        alt="Tô Giấy"
                                        className="featured-image teaser ls-is-cached lazyloaded"
                                      />
                                    </span>
                                    <img
                                      src="./assets/imgs/Togiay2.png"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-56_f2d259f0-c103-45ed-a2c9-4a07472267dc_compact.jpg?v=1535710571"
                                      alt="Tô Giấy"
                                      className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                    />
                                  </div>
                                </div>
                              </a>
                              <div className="product_right_tag"></div>
                              <div className="ImageWrapper">
                                <div className="product-button dt-sc_icon icon-right">
                                  <div>
                                    <a title="Select Options" href=" ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                          ></path>
                                          <path
                                            d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      href=" "
                                      aria-label="Thêm vào danh sách"
                                      data-product_handle="pressure-cooker-5-litre"
                                      data-product-handle="pressure-cooker-5-litre"
                                      className="dt-sc-btn dT_WhishListAddBtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-filled"
                                      >
                                        <path
                                          d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                        ></path>
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-empty"
                                      >
                                        <path
                                          d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                        ></path>
                                      </svg>
                                      <span>Thêm vào danh sách</span>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      data-url="/products/pressure-cooker-5-litre?view=quickview"
                                      href=" "
                                      data-product-id="1478668943425"
                                      data-slider-type="slider_gallery"
                                      data-effect="mfp-move-from-top"
                                      className="product-thumb-full-quick-view popup-product quick-view-btn"
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail content-center">
                              <h6 className="grid-link__title">
                                <a href=" ">Tô Giấy</a>
                              </h6>
                              <span
                                data-id="1478668943425"
                                className="shopify-product-reviews-badge"
                              ></span>
                              <div className="grid-link__meta">
                                <div className="product_price">
                                  {/* --------Giá sản phẩm-------- */}
                                  {/* <div
                                    id="ProductPrice"
                                    className="grid-link__org_price"
                                  >
                                    <span className="money">$469.00</span>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          id="product-1478682738753"
                          className="grid-item product-grid-item"
                        >
                          {/* --------New product 2-------- */}
                          <div className="products">
                            <div className="product-container">
                              <a href=" " className="grid-link product-group">
                                <div className="image_group">
                                  <div className="ImageOverlayCa"></div>
                                  <div className="reveal">
                                    <span className="product-additional">
                                      <img
                                        src="./assets/imgs/Hu1.png"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt11_compact.jpg?v=1535712389"
                                        alt="Hũ Sốt Nắp Liền"
                                        className="featured-image teaser ls-is-cached lazyloaded"
                                      />
                                    </span>
                                    <img
                                      src="./assets/imgs/Hu2.png"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-50_c2499668-20ad-46ce-9edb-08f3c8c9ff7b_compact.jpg?v=1535712389"
                                      alt="Hũ Sốt Nắp Liền"
                                      className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                    />
                                  </div>
                                </div>
                              </a>
                              <div className="product_right_tag"></div>
                              <div className="ImageWrapper">
                                <div className="product-button dt-sc_icon icon-right">
                                  <div>
                                    <a title="Select Options" href=" ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                          ></path>
                                          <path
                                            d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      href=" "
                                      aria-label="Thêm vào danh sách"
                                      data-product_handle="turbo-button-mixer"
                                      data-product-handle="turbo-button-mixer"
                                      className="dt-sc-btn dT_WhishListAddBtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-filled"
                                      >
                                        <path
                                          d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                        ></path>
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-empty"
                                      >
                                        <path
                                          d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                        ></path>
                                      </svg>
                                      <span>Thêm vào danh sách</span>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      data-url="/products/turbo-button-mixer?view=quickview"
                                      href=" "
                                      data-product-id="1478682738753"
                                      data-slider-type="slider_gallery"
                                      data-effect="mfp-move-from-top"
                                      className="product-thumb-full-quick-view popup-product quick-view-btn"
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail content-center">
                              <h6 className="grid-link__title">
                                <a href=" ">Hũ Sốt Nắp Liền</a>
                              </h6>
                              <span
                                data-id="1478682738753"
                                className="shopify-product-reviews-badge"
                              ></span>
                              {/* <div className="grid-link__meta">
                                <div className="product_price">
                                  <div
                                    id="ProductPrice"
                                    className="grid-link__org_price"
                                  >
                                    <span className="money">$329.00</span>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </li>
                        <li
                          id="product-1384453046337"
                          className="grid-item product-grid-item"
                        >
                          {/* --------New product 3-------- */}
                          <div className="products">
                            <div className="product-container">
                              <a href=" " className="grid-link product-group">
                                <div className="image_group">
                                  <div className="ImageOverlayCa"></div>
                                  <div className="reveal">
                                    <span className="product-additional">
                                      <img
                                        src="./assets/imgs/Tuirac.png"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-62_compact.jpg?v=1531907360"
                                        alt="Túi Đựng Rác"
                                        className="featured-image teaser ls-is-cached lazyloaded"
                                      />
                                    </span>
                                    <img
                                      src="./assets/imgs/Tuirac2.png"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-63_compact.jpg?v=1531907360"
                                      alt="Túi Đựng Rác"
                                      className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                    />
                                  </div>
                                </div>
                              </a>
                              <div className="product_right_tag"></div>
                              <div className="ImageWrapper">
                                <div className="product-button dt-sc_icon icon-right">
                                  <div>
                                    <a title="Select Options" href=" ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                          ></path>
                                          <path
                                            d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      href=" "
                                      aria-label="Thêm vào danh sách"
                                      data-product_handle="burner-stove-with-oven"
                                      data-product-handle="burner-stove-with-oven"
                                      className="dt-sc-btn dT_WhishListAddBtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-filled"
                                      >
                                        <path
                                          d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                        ></path>
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-empty"
                                      >
                                        <path
                                          d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                        ></path>
                                      </svg>
                                      <span>Thêm vào danh sách</span>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      data-url="/products/burner-stove-with-oven?view=quickview"
                                      href=" "
                                      data-product-id="1384453046337"
                                      data-slider-type="slider_gallery"
                                      data-effect="mfp-move-from-top"
                                      className="product-thumb-full-quick-view popup-product quick-view-btn"
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail content-center">
                              <h6 className="grid-link__title">
                                <a href=" ">Túi Đựng Rác</a>
                              </h6>
                              <span
                                data-id="1384453046337"
                                className="shopify-product-reviews-badge"
                              ></span>
                              <div className="grid-link__meta">
                                {/* <div className="product_price">
                                  <div
                                    id="ProductPrice"
                                    className="grid-link__org_price"
                                  >
                                    <span className="money">$530.00</span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          id="product-1384452784193"
                          className="grid-item product-grid-item"
                        >
                          {/* --------New product 4-------- */}
                          <div className="products">
                            <div className="product-container">
                              <a href=" " className="grid-link product-group">
                                <div className="image_group">
                                  <div className="ImageOverlayCa"></div>
                                  <div className="reveal">
                                    <span className="product-additional">
                                      <img
                                        src="./assets/imgs/HopcomHT25.png"
                                        data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-47_compact.jpg?v=1531907346"
                                        alt="Khay Hộp Nhựa 4 Ngăn"
                                        className="featured-image teaser ls-is-cached lazyloaded"
                                      />
                                    </span>
                                    <img
                                      src="./assets/imgs/HopcomHT25_2.png"
                                      data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-59_compact.jpg?v=1531907346"
                                      alt="Khay Hộp Nhựa 4 Ngăn"
                                      className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                    />
                                  </div>
                                </div>
                              </a>
                              <div className="product_right_tag"></div>
                              <div className="ImageWrapper">
                                <div className="product-button dt-sc_icon icon-right">
                                  <div>
                                    <a title="Select Options" href=" ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                          ></path>
                                          <path
                                            d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      href=" "
                                      aria-label="Thêm vào danh sách"
                                      data-product_handle="rice-cooker-for-1-liter"
                                      data-product-handle="rice-cooker-for-1-liter"
                                      className="dt-sc-btn dT_WhishListAddBtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-filled"
                                      >
                                        <path
                                          d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                        ></path>
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                        className="heart-empty"
                                      >
                                        <path
                                          d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                        ></path>
                                      </svg>
                                      <span>Thêm vào danh sách</span>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      data-url="/products/rice-cooker-for-1-liter?view=quickview"
                                      href=" "
                                      data-product-id="1384452784193"
                                      data-slider-type="slider_gallery"
                                      data-effect="mfp-move-from-top"
                                      className="product-thumb-full-quick-view popup-product quick-view-btn"
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail content-center">
                              <h6 className="grid-link__title">
                                <a href=" ">Khay Hộp Nhựa 4 Ngăn</a>
                              </h6>
                              <span
                                data-id="1384452784193"
                                className="shopify-product-reviews-badge"
                              ></span>
                              <div className="grid-link__meta">
                                <div className="product_price">
                                  {/* <div
                                    id="ProductPrice"
                                    className="grid-link__org_price"
                                  >
                                    <span className="money">$699.00</span>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  id="shopify-section-index-sidebar-promo2"
                  className="shopify-section"
                ></div>

                <div
                  id="shopify-section-index-sidebar-icon-text"
                  className="shopify-section index-section"
                >
                  <div
                    data-section-id="index-sidebar-icon-text"
                    data-section-type="custom-text-type-1-icon-text"
                    className="custom-text-type-1 icon-text"
                  >
                    <div className="grid-uniform">
                      <ul
                        className="support_block widget"
                        style={{
                          background: "rgba(0, 0, 0, 0)",
                          float: "left",
                          width: "100%",
                        }}
                      >
                        <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href=" ">
                                  <img src="./assets/imgs/icons-6_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>
                                  Miễn phí giao hàng
                                </h5>

                                <p style={{ color: "#848484" }}>
                                  Giao hàng tận nơi
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href=" ">
                                  <img src="./assets/imgs/icons-5_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>
                                  Hỗ Trợ 24/7
                                </h5>

                                <p style={{ color: "#848484" }}>Đáng tin cậy</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href=" ">
                                  <img src="./assets/imgs/icons-3_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>Tiết Kiệm</h5>

                                <p style={{ color: "#848484" }}>
                                  Giá thấp nhất
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href=" ">
                                  <img src="./assets/imgs/icons-2_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>Hoàn Tiền</h5>

                                <p style={{ color: "#848484" }}>
                                  Dễ dàng hoàn trả
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href=" ">
                                  <img src="./assets/imgs/icons-1_ae90bda5-bac5-4a55-8a46-a3db2ff2f473_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>
                                  Của Hàng Trực Tuyến
                                </h5>

                                <p style={{ color: "#848484" }}>
                                  Giao dịch dễ dàng
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        {/* <li
                          className="grid__item wow fadeInUp"
                          data-wow-delay="ms"
                        >
                          <div className="custom-text_section">
                            <div className="support_section">
                              <div className="support_icon">
                                <a href="">
                                  <img src="./assets/imgs/icons-4_small.png" />
                                </a>
                              </div>

                              <div className="support_text">
                                <h5 style={{ color: "#000000" }}>
                                  Award Winner
                                </h5>

                                <p style={{ color: "#848484" }}>
                                  For best services
                                </p>
                              </div>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  id="shopify-section-index-sidebar-promo3"
                  className="shopify-section"
                ></div>
              </div>

              <div className="main-cotainer">
                <div
                  id="shopify-section-16207297747b1d3c2f"
                  className="shopify-section index-section home-slideshow-section home-slider-width-promo-images"
                >
                  <div
                    className="container-fluid"
                    data-animation-speed="5000"
                    data-autoplay="true"
                    data-section-id="16207297747b1d3c2f"
                    data-section-type="slideshow-section"
                    style={{
                      marginBottom: "60px",
                      marginTop: "30px",
                      paddingBottom: "px",
                      paddingTop: "px",
                    }}
                  >
                    <div className="row">
                      <div className="grouped-content">
                        {/* <div className="main-block">
                          <div className="dt-sc-swiper-slider swiper-container">
                            <div
                              id="home-slider-16207297747b1d3c2f"
                              className="swiper-container-initialized swiper-container-horizontal swiper-container-autoheight"
                            >
                              <div
                                className="swiper-wrapper"
                                style={{
                                  height: "514px",
                                  transform: "translate3d(0px, 0px, 0px)",
                                }}
                              >
                                <div
                                  className="slider_style_3 slider-16207297747b1d3c2f-0-list swiper-slide swiper-slide-active"
                                  style={{ width: "586px" }}
                                >
                                  <img
                                    className="slide-img"
                                    src="./assets/imgs/img-2-1_fe2a72af-00c0-4474-8c29-ffcc9027bba5.jpg"
                                    alt=""
                                  />

                                  <div className="slider-content slider-content-bg text-center top-right">
                                    <div className="slide_1">
                                      <div className="multiple-buttons"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span
                                className="swiper-notification"
                                aria-live="assertive"
                                aria-atomic="true"
                              ></span>
                            </div>
                          </div>
                        </div> */}

                        <div className="additional-block dt-sc-column default">
                          <div className="dt-sc-promo-block">
                            <a href=" ">
                              <div className="dt-sc-image-gallery">
                                <img
                                  className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                  src="./assets/imgs/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg"
                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg?v=1613763296"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.5088408644400786"
                                  data-sizes="auto"
                                  data-alt=""
                                  sizes="301px"
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_480x480@2x.jpg?v=1613763296"
                                    className="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </div>
                            </a>

                            <div className="featured-content"></div>
                          </div>

                          <div className="dt-sc-promo-block">
                            <div className="dt-sc-image-gallery">
                              <img
                                className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                src="./assets/imgs/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg"
                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg?v=1613763296"
                                data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                data-aspectratio="1.5088408644400786"
                                data-sizes="auto"
                                data-alt=""
                                sizes="301px"
                              />
                              <noscript>
                                <img
                                  src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_480x480@2x.jpg?v=1613763296"
                                  className="dt-sc-noscript-image"
                                  alt=""
                                />
                              </noscript>
                            </div>

                            <div className="featured-content"></div>
                          </div>
                        </div>
                        <div className="additional-block dt-sc-column default">
                          <div className="dt-sc-promo-block">
                            <a href=" ">
                              <div className="dt-sc-image-gallery">
                                <img
                                  className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                  src="./assets/imgs/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg"
                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg?v=1613763296"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.5088408644400786"
                                  data-sizes="auto"
                                  data-alt=""
                                  sizes="301px"
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_480x480@2x.jpg?v=1613763296"
                                    className="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </div>
                            </a>

                            <div className="featured-content"></div>
                          </div>

                          <div className="dt-sc-promo-block">
                            <div className="dt-sc-image-gallery">
                              <img
                                className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                src="./assets/imgs/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg"
                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg?v=1613763296"
                                data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                data-aspectratio="1.5088408644400786"
                                data-sizes="auto"
                                data-alt=""
                                sizes="301px"
                              />
                              <noscript>
                                <img
                                  src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_480x480@2x.jpg?v=1613763296"
                                  className="dt-sc-noscript-image"
                                  alt=""
                                />
                              </noscript>
                            </div>

                            <div className="featured-content"></div>
                          </div>
                        </div>
                        <div className="additional-block dt-sc-column default">
                          <div className="dt-sc-promo-block">
                            <a href=" ">
                              <div className="dt-sc-image-gallery">
                                <img
                                  className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                  src="./assets/imgs/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg"
                                  data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_large.jpg?v=1613763296"
                                  data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                  data-aspectratio="1.5088408644400786"
                                  data-sizes="auto"
                                  data-alt=""
                                  sizes="301px"
                                />
                                <noscript>
                                  <img
                                    src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-3-1_bf36ce31-70d4-4c9d-b30c-b2501acec9f5_480x480@2x.jpg?v=1613763296"
                                    className="dt-sc-noscript-image"
                                  />
                                </noscript>
                              </div>
                            </a>

                            <div className="featured-content"></div>
                          </div>

                          <div className="dt-sc-promo-block">
                            <div className="dt-sc-image-gallery">
                              <img
                                className="dt-sc-block-image lazyautosizes ls-is-cached lazyloaded"
                                src="./assets/imgs/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg"
                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_large.jpg?v=1613763296"
                                data-widths="[180, 360, 470, 600, 770, 970, 1060, 1280, 1512, 1728, 2048]"
                                data-aspectratio="1.5088408644400786"
                                data-sizes="auto"
                                data-alt=""
                                sizes="301px"
                              />
                              <noscript>
                                <img
                                  src="//dt-kitchen-utensils.myshopify.com/cdn/shop/files/img-4-1_926ee1a3-0779-4c46-a1dd-b3e0ed49e8f7_480x480@2x.jpg?v=1613763296"
                                  className="dt-sc-noscript-image"
                                  alt=""
                                />
                              </noscript>
                            </div>

                            <div className="featured-content"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="shopify-section-1620703177524cb845"
                  className="shopify-section index-section home-product-carousel home-product-carousel"
                >
                  <div
                    className="dt-sc-section-wrapper"
                    data-style="parallax"
                    style={{
                      backgroundPosition: "50% 782.781px",
                      marginBottom: "100px",
                      marginTop: "20px",
                      paddingBottom: "0px",
                      paddingTop: "20px",
                    }}
                  >
                    {" "}
                    <div className="container">
                      <div className="row first-product-carousel">
                        <div className="line-span dt-sc-heading text-center">
                          <h3 className="dt-sc-main-heading">Sản phẩm</h3>

                          <span></span>

                          <h5 className="dt-sc-sub-heading">
                            Dễ dàng, Tiện lợi và Thân thiện
                          </h5>
                        </div>

                        <div className="main-block main-product-carousel">
                          <div
                            data-section-id="blockCarousel"
                            data-section-type="home-blockCarousel-section"
                          >
                            <div className="dT_vDynamicPWrap-1620703177524cb845 dT_vProdWrap">
                              <ul className="grid product-collection dt-sc-column three-column product-list-style">
                                {/* -----Product 1----- */}
                                <li
                                  id="product-1479935983681"
                                  className="grid-item product-grid-item"
                                >
                                  <div className="products">
                                    <div className="product-container">
                                      <a
                                        href="https://shopee.vn/(100-ca%CC%81i)-H%C5%A9-S%E1%BB%91t-N%E1%BA%AFp-Li%E1%BB%81n-2oz-4oz-(H%C5%A9-%C4%90%E1%BB%B1ng-N%C6%B0%E1%BB%9Bc-Ch%E1%BA%A5m-%C4%90%E1%BB%B1ng-Panacota-%C4%90%E1%BB%B1ng-S%E1%BB%91t-%C4%90%E1%BB%B1ng-B%C3%A1nh-Flan)-i.1237909449.26558202303"
                                        className="grid-link product-group"
                                      >
                                        <div className="image_group">
                                          <div className="ImageOverlayCa"></div>
                                          <div className="reveal">
                                            <span className="product-additional">
                                              <img
                                                src="./assets/imgs/Hu1.png"
                                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt8.jpg?v=1535777526"
                                                alt="Style Vacuum Flask"
                                                className="featured-image teaser ls-is-cached lazyloaded"
                                              />
                                            </span>
                                            <img
                                              src="./assets/imgs/Hu2.png"
                                              data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/product-18_75a391d3-48bc-413e-be2e-c27ddba5949b.jpg?v=1535777526"
                                              alt="Style Vacuum Flask"
                                              className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="product_right_tag"></div>
                                      <div className="ImageWrapper">
                                        <div className="product-button dt-sc_icon icon-right">
                                          <div>
                                            <a
                                              title="Select Options"
                                              href="https://shopee.vn/(100-ca%CC%81i)-H%C5%A9-S%E1%BB%91t-N%E1%BA%AFp-Li%E1%BB%81n-2oz-4oz-(H%C5%A9-%C4%90%E1%BB%B1ng-N%C6%B0%E1%BB%9Bc-Ch%E1%BA%A5m-%C4%90%E1%BB%B1ng-Panacota-%C4%90%E1%BB%B1ng-S%E1%BB%91t-%C4%90%E1%BB%B1ng-B%C3%A1nh-Flan)-i.1237909449.26558202303"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                  ></path>
                                                  <path
                                                    d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href=" "
                                              aria-label="Thêm vào danh sách"
                                              data-product_handle="style-vacuum-flask"
                                              data-product-handle="style-vacuum-flask"
                                              className="dt-sc-btn dT_WhishListAddBtn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-filled"
                                              >
                                                <path
                                                  d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                ></path>
                                              </svg>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-empty"
                                              >
                                                <path
                                                  d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                ></path>
                                              </svg>
                                              <span>Thêm vào danh sách</span>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              data-url="/products/style-vacuum-flask?view=quickview"
                                              href="https://shopee.vn/(100-ca%CC%81i)-H%C5%A9-S%E1%BB%91t-N%E1%BA%AFp-Li%E1%BB%81n-2oz-4oz-(H%C5%A9-%C4%90%E1%BB%B1ng-N%C6%B0%E1%BB%9Bc-Ch%E1%BA%A5m-%C4%90%E1%BB%B1ng-Panacota-%C4%90%E1%BB%B1ng-S%E1%BB%91t-%C4%90%E1%BB%B1ng-B%C3%A1nh-Flan)-i.1237909449.26558202303"
                                              data-product-id="1479935983681"
                                              data-slider-type="slider_gallery"
                                              data-effect="mfp-move-from-top"
                                              className="product-thumb-full-quick-view popup-product quick-view-btn"
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-detail content-center">
                                      <h6 className="grid-link__title">
                                        <a href="https://shopee.vn/(100-ca%CC%81i)-H%C5%A9-S%E1%BB%91t-N%E1%BA%AFp-Li%E1%BB%81n-2oz-4oz-(H%C5%A9-%C4%90%E1%BB%B1ng-N%C6%B0%E1%BB%9Bc-Ch%E1%BA%A5m-%C4%90%E1%BB%B1ng-Panacota-%C4%90%E1%BB%B1ng-S%E1%BB%91t-%C4%90%E1%BB%B1ng-B%C3%A1nh-Flan)-i.1237909449.26558202303">
                                          Hũ Sốt Nắp Liền (2oz, 4oz)
                                        </a>
                                      </h6>
                                      <span
                                        data-id="1479935983681"
                                        className="shopify-product-reviews-badge"
                                      ></span>
                                      <div className="grid-link__meta">
                                        <div className="product_price">
                                          <div
                                            id="ProductPrice"
                                            className="grid-link__org_price"
                                          >
                                            {/* <span className="money">
                                              ₫22.000 - ₫62.000
                                            </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {/* -----Product 2----- */}
                                <li
                                  id="product-1479926382657"
                                  className="grid-item product-grid-item on-sale"
                                >
                                  <div className="products">
                                    <div className="product-container">
                                      <a
                                        href="https://shopee.vn/(1KG)-3Cu%E1%BB%99n-B%E1%BB%8Dc-%C4%90%E1%BB%B1ng-R%C3%A1c-T%C3%BAi-%C4%90%E1%BB%B1ng-R%C3%A1c-T%E1%BB%B1-Ph%C3%A2n-Hu%E1%BB%B7-3-Size-Ti%E1%BB%83u-Trung-%C4%90%E1%BA%A1i-M%C3%A0u-v%C3%A0-%C4%90en-i.1237909449.26407789786"
                                        className="grid-link product-group"
                                      >
                                        {/* <div className="featured-tag">
                                          <span className="badge badge--sale">
                                            <span className="gift-tag badge__text">
                                              Sale
                                            </span>
                                          </span>
                                        </div> */}
                                        <div className="image_group">
                                          <div className="ImageOverlayCa"></div>
                                          <div className="reveal">
                                            <span className="product-additional">
                                              <img
                                                src="./assets/imgs/Tuirac.png"
                                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt2_00d67c16-8802-4b91-99a3-4a6e7887d438.jpg?v=1535777062"
                                                alt="400-Watt Juicer (Black)"
                                                className="featured-image teaser ls-is-cached lazyloaded"
                                              />
                                            </span>
                                            <img
                                              src="./assets/imgs/Tuirac2.png"
                                              data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt1_6c51a071-f1f8-4ecc-883f-274cabd46df7.jpg?v=1535777062"
                                              alt="400-Watt Juicer (Black)"
                                              className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="product_right_tag offer_exist"></div>
                                      <div className="ImageWrapper">
                                        <div className="product-button dt-sc_icon icon-right">
                                          <div>
                                            <a title="Select Options" href=" ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                  ></path>
                                                  <path
                                                    d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href=" "
                                              aria-label="Thêm vào danh sách"
                                              data-product_handle="400-watt-juicer-black"
                                              data-product-handle="400-watt-juicer-black"
                                              className="dt-sc-btn dT_WhishListAddBtn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-filled"
                                              >
                                                <path
                                                  d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                ></path>
                                              </svg>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-empty"
                                              >
                                                <path
                                                  d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                ></path>
                                              </svg>
                                              <span>Thêm vào danh sách</span>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              data-url="/products/400-watt-juicer-black?view=quickview"
                                              href="https://shopee.vn/(1KG)-3Cu%E1%BB%99n-B%E1%BB%8Dc-%C4%90%E1%BB%B1ng-R%C3%A1c-T%C3%BAi-%C4%90%E1%BB%B1ng-R%C3%A1c-T%E1%BB%B1-Ph%C3%A2n-Hu%E1%BB%B7-3-Size-Ti%E1%BB%83u-Trung-%C4%90%E1%BA%A1i-M%C3%A0u-v%C3%A0-%C4%90en-i.1237909449.26407789786"
                                              data-product-id="1479926382657"
                                              data-slider-type="slider_gallery"
                                              data-effect="mfp-move-from-top"
                                              className="product-thumb-full-quick-view popup-product quick-view-btn"
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-detail content-center">
                                      <h6 className="grid-link__title">
                                        <a href="https://shopee.vn/(1KG)-3Cu%E1%BB%99n-B%E1%BB%8Dc-%C4%90%E1%BB%B1ng-R%C3%A1c-T%C3%BAi-%C4%90%E1%BB%B1ng-R%C3%A1c-T%E1%BB%B1-Ph%C3%A2n-Hu%E1%BB%B7-3-Size-Ti%E1%BB%83u-Trung-%C4%90%E1%BA%A1i-M%C3%A0u-v%C3%A0-%C4%90en-i.1237909449.26407789786">
                                          Túi Đựng Rác Tự Phân Huỷ (Tiểu, Trung,
                                          Đại) (3 Màu và Đen)
                                        </a>
                                      </h6>
                                      <span
                                        data-id="1479926382657"
                                        className="shopify-product-reviews-badge"
                                      ></span>
                                      <div className="grid-link__meta">
                                        <div className="product_price">
                                          <div
                                            id="ProductPrice"
                                            className="grid-link__org_price"
                                          >
                                            {/* <span className="money">
                                              ₫23.000 - ₫24.900
                                            </span> */}
                                          </div>
                                        </div>
                                        <del
                                          id="ComparePrice"
                                          className="grid-link__sale_price"
                                        >
                                          <span className="money"></span>
                                        </del>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {/* -----Product 3----- */}
                                <li
                                  id="product-1478744506433"
                                  className="grid-item product-grid-item on-sale"
                                >
                                  <div className="products">
                                    <div className="product-container">
                                      <a
                                        href=" "
                                        className="grid-link product-group"
                                      >
                                        <div className="featured-tag">
                                          <span className="badge badge--sale">
                                            {/* <span className="gift-tag badge__text">
                                              Sale
                                            </span> */}
                                          </span>
                                        </div>
                                        <div className="image_group">
                                          <div className="ImageOverlayCa"></div>
                                          <div className="reveal">
                                            <span className="product-additional">
                                              <img
                                                src="./assets/imgs/HopcomHT25.png"
                                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt15_feaf86f8-6175-4781-b79f-09b6863a524c.jpg?v=1535719104"
                                                alt="Smooth Juicer"
                                                className="featured-image teaser ls-is-cached lazyloaded"
                                              />
                                            </span>
                                            <img
                                              src="./assets/imgs/HopcomHT25_2.png"
                                              data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt1_grande_99aadc74-4022-4766-afe4-eede9640e485.jpg?v=1638615750"
                                              alt="Smooth Juicer"
                                              className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="product_right_tag offer_exist"></div>
                                      <div className="ImageWrapper">
                                        <div className="product-button dt-sc_icon icon-right">
                                          <div>
                                            <a title="Select Options" href=" ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                  ></path>
                                                  <path
                                                    d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href=" "
                                              aria-label="Thêm vào danh sách"
                                              data-product_handle="smooth-juicer"
                                              data-product-handle="smooth-juicer"
                                              className="dt-sc-btn dT_WhishListAddBtn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-filled"
                                              >
                                                <path
                                                  d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                ></path>
                                              </svg>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-empty"
                                              >
                                                <path
                                                  d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                ></path>
                                              </svg>
                                              <span>Thêm vào danh sách</span>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              data-url="/products/smooth-juicer?view=quickview"
                                              href=" "
                                              data-product-id="1478744506433"
                                              data-slider-type="slider_gallery"
                                              data-effect="mfp-move-from-top"
                                              className="product-thumb-full-quick-view popup-product quick-view-btn"
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-detail content-center">
                                      <h6 className="grid-link__title">
                                        <a href=" ">
                                          Khay Hộp Nhựa Đen 4 ngăn HT25 (Kèm
                                          Nắp)
                                        </a>
                                      </h6>
                                      <span
                                        data-id="1478744506433"
                                        className="shopify-product-reviews-badge"
                                      ></span>
                                      <div className="grid-link__meta">
                                        <div className="product_price">
                                          <div
                                            id="ProductPrice"
                                            className="grid-link__org_price"
                                          >
                                            {/* <span className="money">
                                              ₫110.000 - ₫400.000
                                            </span> */}
                                          </div>
                                        </div>
                                        {/* <del
                                          id="ComparePrice"
                                          className="grid-link__sale_price"
                                        >
                                          <span className="money">$400.00</span>
                                        </del> */}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {/* -----Product 4----- */}
                                <li
                                  id="product-1478744506433"
                                  className="grid-item product-grid-item on-sale"
                                >
                                  <div className="products">
                                    <div className="product-container">
                                      <a
                                        href=" "
                                        className="grid-link product-group"
                                      >
                                        {/* <div className="featured-tag">
                                          <span className="badge badge--sale">
                                            <span className="gift-tag badge__text">
                                              Sale
                                            </span>
                                          </span>
                                        </div> */}
                                        <div className="image_group">
                                          <div className="ImageOverlayCa"></div>
                                          <div className="reveal">
                                            <span className="product-additional">
                                              <img
                                                src="./assets/imgs/HopcomHT17.png"
                                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt15_feaf86f8-6175-4781-b79f-09b6863a524c.jpg?v=1535719104"
                                                alt="Smooth Juicer"
                                                className="featured-image teaser ls-is-cached lazyloaded"
                                              />
                                            </span>
                                            <img
                                              src="./assets/imgs/HopcomHT17_2.png"
                                              data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt1_grande_99aadc74-4022-4766-afe4-eede9640e485.jpg?v=1638615750"
                                              alt="Smooth Juicer"
                                              className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="product_right_tag offer_exist"></div>
                                      <div className="ImageWrapper">
                                        <div className="product-button dt-sc_icon icon-right">
                                          <div>
                                            <a title="Select Options" href=" ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                  ></path>
                                                  <path
                                                    d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href=" "
                                              aria-label="Thêm vào danh sách"
                                              data-product_handle="smooth-juicer"
                                              data-product-handle="smooth-juicer"
                                              className="dt-sc-btn dT_WhishListAddBtn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-filled"
                                              >
                                                <path
                                                  d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                ></path>
                                              </svg>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-empty"
                                              >
                                                <path
                                                  d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                ></path>
                                              </svg>
                                              <span>Thêm vào danh sách</span>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              data-url="/products/smooth-juicer?view=quickview"
                                              href=" "
                                              data-product-id="1478744506433"
                                              data-slider-type="slider_gallery"
                                              data-effect="mfp-move-from-top"
                                              className="product-thumb-full-quick-view popup-product quick-view-btn"
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-detail content-center">
                                      <h6 className="grid-link__title">
                                        <a href=" ">
                                          Khay Hộp Nhựa Đen 1-2 ngăn HT17 (Kèm
                                          Nắp)
                                        </a>
                                      </h6>
                                      <span
                                        data-id="1478744506433"
                                        className="shopify-product-reviews-badge"
                                      ></span>
                                      <div className="grid-link__meta">
                                        <div className="product_price">
                                          <div
                                            id="ProductPrice"
                                            className="grid-link__org_price"
                                          >
                                            {/* <span className="money">
                                              ₫55.000 - ₫58.000
                                            </span> */}
                                          </div>
                                        </div>
                                        {/* <del
                                          id="ComparePrice"
                                          className="grid-link__sale_price"
                                        >
                                          <span className="money">$400.00</span>
                                        </del> */}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {/* -----Product 5----- */}
                                <li
                                  id="product-1478744506433"
                                  className="grid-item product-grid-item on-sale"
                                >
                                  <div className="products">
                                    <div className="product-container">
                                      <a
                                        href=" "
                                        className="grid-link product-group"
                                      >
                                        {/* <div className="featured-tag">
                                          <span className="badge badge--sale">
                                            <span className="gift-tag badge__text">
                                              Sale
                                            </span>
                                          </span>
                                        </div> */}
                                        <div className="image_group">
                                          <div className="ImageOverlayCa"></div>
                                          <div className="reveal">
                                            <span className="product-additional">
                                              <img
                                                src="./assets/imgs/Togiay.png"
                                                data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt15_feaf86f8-6175-4781-b79f-09b6863a524c.jpg?v=1535719104"
                                                alt="Smooth Juicer"
                                                className="featured-image teaser ls-is-cached lazyloaded"
                                              />
                                            </span>
                                            <img
                                              src="./assets/imgs/Togiay2.png"
                                              data-src="//dt-kitchen-utensils.myshopify.com/cdn/shop/products/pdt1_grande_99aadc74-4022-4766-afe4-eede9640e485.jpg?v=1638615750"
                                              alt="Smooth Juicer"
                                              className="hidden-feature_img teaser ls-is-cached lazyloaded"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="product_right_tag offer_exist"></div>
                                      <div className="ImageWrapper">
                                        <div className="product-button dt-sc_icon icon-right">
                                          <div>
                                            <a title="Select Options" href=" ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M52.2,69.6l-14,14c-3,3-7,4.5-10.9,4.5s-8-1.5-10.9-4.5c-6.1-6.1-6.1-15.8,0-21.8l13.4-13.4c2.8-3,6.8-4.5,10.9-4.5
		s8,1.7,10.9,4.5c3.3,3.3,8.3,4,12.3,2c-1.2-3.2-3.2-6.2-5.7-8.7C48.5,32,32.7,32,23,41.7L9.7,55c-9.7,9.7-9.7,25.5,0,35.3
		c4.7,4.7,10.9,7.2,17.6,7.2s12.9-2.6,17.7-7.2l13.4-13.4c1.9-2,3.6-4.3,4.7-6.6c-1.3,0.1-2.6,0.2-3.9,0.2
		C56.8,70.5,54.4,70.2,52.2,69.6z"
                                                  ></path>
                                                  <path
                                                    d="M90.2,9.8C80.4,0,64.6,0,54.9,9.8L41.5,23.2c-1.9,1.9-3.6,4.3-4.7,6.6c3.7-0.5,7.4-0.2,10.9,0.6c0.1-0.2,0.4-0.4,0.5-0.6
		l13.4-13.4c6.1-6.1,15.8-6.1,21.8,0c6.1,6.1,6.1,15.8,0,21.8L70.1,51.7c-0.5,0.6-1.1,1.1-1.7,1.4c0,0-0.1,0-0.1,0.1
		c-2.7,2-5.9,3-9.1,3c-3.9,0-8-1.5-10.9-4.5c-2-2-4.7-3.1-7.6-3.1c-1.7,0-3.3,0.4-4.7,1.2c1.2,3.2,3.2,6.2,5.7,8.7
		c9.7,9.7,25.5,9.7,35.3,0L90.3,45c4.7-4.7,7.4-10.9,7.4-17.7S94.9,14.4,90.2,9.8z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href=" "
                                              aria-label="Thêm vào danh sách"
                                              data-product_handle="smooth-juicer"
                                              data-product-handle="smooth-juicer"
                                              className="dt-sc-btn dT_WhishListAddBtn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-filled"
                                              >
                                                <path
                                                  d="M87.9,15.9c-10.6-10.6-27.5-10.6-38.1,0c-10.6-10.6-27.5-10.6-38.1,0S1.1,43.4,11.7,54l38.1,38.1L87.9,54
	C99,43.4,99,26.4,87.9,15.9z M81.1,30.9c-2.6,2.6-7.4,2.6-10.1,0c-2.6-2.6-2.6-7.4,0-10.1c2.6-2.6,7.4-2.6,10.1,0
	S83.7,28.3,81.1,30.9z"
                                                ></path>
                                              </svg>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                                className="heart-empty"
                                              >
                                                <path
                                                  d="M88.5,15.3c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1c-5.4-5.4-12.4-8.1-19.4-8.1s-14,2.7-19.4,8.1
		C0.3,26.1,0.3,43.3,11.1,54l38.7,38.7L88.5,54C99.8,43.3,99.8,26,88.5,15.3z M83.5,48.9L83.5,48.9L49.8,82.6L16.2,48.9
		c-8-8-8-20.5,0-28.5c3.8-3.8,8.9-6,14.3-6s10.4,2.1,14.3,6l5.1,5.1l5.1-5.1c3.8-3.8,8.9-6,14.3-6c5.3,0,10.4,2.1,14.3,6l0.1,0.1
		l0.1,0.1c4,3.8,6.2,8.8,6.2,14C89.8,39.9,87.6,45,83.5,48.9L83.5,48.9z"
                                                ></path>
                                              </svg>
                                              <span>Thêm vào danh sách</span>
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              data-url="/products/smooth-juicer?view=quickview"
                                              href=" "
                                              data-product-id="1478744506433"
                                              data-slider-type="slider_gallery"
                                              data-effect="mfp-move-from-top"
                                              className="product-thumb-full-quick-view popup-product quick-view-btn"
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M76.4,69.4c13.9-17.8,10.8-43.5-7-57.4C51.6-1.9,25.9,1.2,12,19C-1.9,36.8,1.2,62.5,19,76.4C33.8,88,54.6,88,69.4,76.4
	l18.8,18.8c1.9,1.9,5.1,2,7,0c1.9-1.9,2-5.1,0-7c0,0,0,0,0,0L76.4,69.4z M44.3,75.1c-17.1,0-30.9-13.8-30.9-30.9
	c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9c0,0,0,0,0,0C75.1,61.3,61.3,75.1,44.3,75.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-detail content-center">
                                      <h6 className="grid-link__title">
                                        <a href=" ">
                                          Tô Giấy Bát Giấy Kraft Kèm Nắp (750ml,
                                          1000ml)
                                        </a>
                                      </h6>
                                      <span
                                        data-id="1478744506433"
                                        className="shopify-product-reviews-badge"
                                      ></span>
                                      <div className="grid-link__meta">
                                        <div className="product_price">
                                          <div
                                            id="ProductPrice"
                                            className="grid-link__org_price"
                                          >
                                            {/* <span className="money">
                                              ₫42.000 - ₫185.000
                                            </span> */}
                                          </div>
                                        </div>
                                        {/* <del
                                          id="ComparePrice"
                                          className="grid-link__sale_price"
                                        >
                                          <span className="money">$400.00</span>
                                        </del> */}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      {/* ----------------------footer ở đây-------------------- */}
      <div id="shopify-section-footer" className="shopify-section">
        <footer
          className="site-footer"
          data-section-id="footer"
          data-section-type="footer-section"
          style={{
            backgroundImage:
              "url('//dt-kitchen-utensils.myshopify.com/cdn/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            marginTop: "40px",
          }}
        >
          <div
            className="site-footer__top"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <div className="container">
              <div className="row">
                <div className="dt-sc-flex-space-between footer-align">
                  <div className="footer__item--3ae2a44c-6425-4746-b0ab-44454f47cb25 footer-contact-info">
                    <div className="text-start">
                      <h4 className="footer__title">Liên hệ chúng tôi</h4>
                      <ul className="contact-info">
                        <li className="address">
                          <address>
                            <i className="fa fa-home"></i>HCM Office: 344 Nguyễn
                            Văn Quỳ, Phường Phú Thuận, Quận 7, TP.HCM
                          </address>
                        </li>
                        <li className="contact-phone">
                          <i className="fa fa-phone"></i>070 809 8515
                        </li>
                        <li className="office-mail">
                          <a href="mailto:kencoshop2024@gmail.com">
                            <i className="fa fa-envelope"></i>
                            kencoshop2024@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="footer__item--55c7a363-48ef-4d8e-b9bf-c930008bde8a footer-links">
                    <div className="text-start">
                      <h4 className="footer__title">Trợ giúp</h4>

                      <ul className="footer_menu dt-sc-list">
                        <li>
                          <a href=" ">Tìm kiếm</a>
                        </li>

                        <li>
                          <a href=" ">Trợ giúp</a>
                        </li>

                        <li>
                          <a href=" ">Thông tin</a>
                        </li>

                        <li>
                          <a href=" ">Chính sách bảo mật</a>
                        </li>

                        <li>
                          <a href=" ">Chi tiết vận chuyển</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="footer__item--f01d3e76-7d47-4753-9d4a-d9f970895cd2 footer-links">
                    <div className="text-start">
                      <h4 className="footer__title">Hỗ trợ</h4>

                      <ul className="footer_menu dt-sc-list">
                        <li>
                          <a href=" ">Liên hệ</a>
                        </li>

                        <li>
                          <a href=" ">Về chúng tôi</a>
                        </li>

                        <li>
                          <a href=" ">Nghề nghiệp</a>
                        </li>

                        <li>
                          <a href=" ">Trả hàng &amp; Hoàn tiền</a>
                        </li>

                        <li>
                          <a href=" ">Giao hàng</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="footer__item--a7eb42ee-501b-4d5e-affd-1f06bc09f583 footer-links">
                    <div className="text-start">
                      <h4 className="footer__title">Thông tin</h4>

                      <ul className="footer_menu dt-sc-list">
                        <li>
                          <a href=" ">Thông tin tìm kiếm</a>
                        </li>

                        <li>
                          <a href=" ">Tìm kiếm nâng cao</a>
                        </li>

                        <li>
                          <a href=" ">Trợ giúp &amp; Câu hỏi thường gặp</a>
                        </li>

                        <li>
                          <a href=" ">Vị trí cửa hàng</a>
                        </li>

                        <li>
                          <a href=" ">Đơn hàng &amp; Hoàn trả</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="site-footer__bottom">
            <div className="container">
              <div className="row">
                <div className="dt-sc-flex-space-between footer-bottom">
                  <div className="footer__copyright grid__item wide--one-third post-large--one-third large--one-third medium--one-whole">
                    <p>
                      © 2024 KenCo | -
                      <a href="#0" target="_blank">
                        Design Themes
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <a
        id="to-top"
        href="#0"
        className="dt-sc-to-top"
        style={{ display: "none" }}
      >
        {" "}
        <i className="fa fa-long-arrow-up"></i>{" "}
      </a>
    </React.Fragment>
  );
}
export default Phome;
