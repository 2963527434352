import React, { useEffect } from "react";
import CpLoading from "../component/cp_loading";

function P404(props) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div>404</div>
      <CpLoading {...props}></CpLoading>
    </React.Fragment>
  );
}

export default P404;
